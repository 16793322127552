import React, { useEffect, useState } from "react";
import "./ManageNotification.css";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/esm/ModalBody";
import ModalFooter from "react-bootstrap/esm/ModalFooter";
import Button from "react-bootstrap/Button";
import Lottie from "react-lottie";
import { FaSearch, FaTrash, FaPen } from "react-icons/fa";
import swal from "sweetalert";
function ManageNotification() {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [notifyImage, setNotifyImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState("");
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [image, setImage] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const handleCloseModal = () => {
    setShowModal(false);
    setImage(false);
    setNotificationType("");
    setTitle([]);
    setNotifyImage("");
    setMessage("");
  };
  const handleImage = () => {
    setImage(true);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleInsert = () => {
    if (!title.trim() || !message.trim()) {
      swal("warning", "Please enter all the details", "warning");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("message", message);
    formData.append("image", notifyImage);

    fetch("https://arasurdigital.com/admin/send_notification.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        fetchdata();
        setTitle("");
        setMessage("");
        setNotifyImage("");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setShowModal(false); // Assuming setShowModal is used to hide the modal after inserting data
  };

  const filteredData = data.filter(
    (item) =>
      item.title.toLowerCase().includes(query.toLowerCase()) ||
      item.message.toLowerCase().includes(query.toLowerCase())
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const visiblePages = 3;

  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + 2, totalPages);

    if (currentPage > 2) {
      paginationNumbers.push(1);
      if (currentPage > 3) {
        paginationNumbers.push("...");
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
    }
    if (endPage < totalPages) {
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const fetchdata = async () => {
    const url = "https://arasurdigital.com/admin/send_notification_get.php";
    const res = await fetch(url);
    const data = await res.json();

    setData(data);
  };
  const handelDelete = async (addpromosId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this notification!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/send_notification_get.php?id=${addpromosId}`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 200) {
          fetchdata();
        } else {
          console.error("Failed to delete promocode");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(filter.length / newItemsPerPage);

    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };
  const handleTypeChange = (event) => {
    setNotificationType(event.target.value); // Update notification type based on selection
    if (event.target.value === "Image") {
      setImage(true);
      // Show image input if image type is selected
    } else {
      setImage(false); // Hide image input if text type is selected
    }
  };
  const handleSearch = (e) => {
    setQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when a new search is made
  };

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setNotifyImage(file);
  };
  useEffect(() => {
    setLoading(true);
    fetchdata()
    .then(() => setLoading(false))
    .catch(() => setLoading(false));
  }, []);
  return (
    <div className="main-container_1">
      <div className="Order-head">
        <h3>Send Notification</h3>
        <p>Dashboard / Send Notification </p>
      </div>

      <div className="card-cat">
        <div className="od_items_69"></div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
            </div>
          </div>

          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={(e) => handleInput(e)}
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
          {systemuserView.add === true ? (
            <button className="add-category-button" onClick={handleShowModal}>
              Add System Users
            </button>
          ) : (
            ""
          )} */}

          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "10px" }}
          >
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleSearch}
              />
              <FaSearch className="search-icon" />
            </div>

            <button className="add-category-button" onClick={handleShowModal}>
              Send Notification
            </button>
          </div>
        </div>
        <div className="table-container text-end ">
        {loading ? (
              <div>
                <Lottie
                  height={250}
                  width={250}
                  options={{
                    loop: true,
                    animationData: require("../Assets/Animation - 1716197548567.json"),
                    autoplay: true,
                  }}
                />
              </div>
            ) : (
          <table id="table-to-xls" className="text-center">
            <thead>
              <tr>
                <th style={{ width: "4%" }}>S.No</th>
                <th style={{ textAlign: "center" }}>Title</th>
                <th style={{ textAlign: "center" }}>Message</th>
                <th style={{ textAlign: "center" }}>Image</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData && currentPageData.length > 0 ? (
                currentPageData.map((item, i) => (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>
                      {startIndex + i + 1}
                    </td>
                    <td style={{ textAlign: "center" }}>{item.title}</td>
                    <td style={{ textAlign: "center" }}>{item.message}</td>
                    <td style={{ textAlign: "center" }}>
                      {item.image ? (
                        <img
                          src={`https://arasurdigital.com/admin/image/notification/${item.image}`}
                          width={40}
                          height={40}
                          style={{ margin: "10px" }}
                          alt="Notification"
                        />
                      ) : (
                        "No Image"
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <button
                        className="btn-delete"
                        onClick={() => handelDelete(item.id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>
          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              <button
                onClick={() => setCurrentPage(page)}
                className="page-link"
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">Add Notification</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <ModalBody>
          <div className="form_1">
            <label>Type</label>
            <select
              onChange={handleTypeChange}
              value={notificationType}
              className="form-select"
            >
              <option value="">Select Notification Type</option>
              <option value="Text">Text</option>
              <option value="Image">Image</option>
            </select>
          </div>
          {image && (
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            ></input>
          )}{" "}
          <div></div>
          <div className="form_1">
            <label>Title</label>
            <input
              type="text"
              placeholder="Enter Title"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form_1">
            <label>Message</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Notification Message Here!"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={handleInsert}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ManageNotification;
