import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Addproduct.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Lottie from "react-lottie";
import Swal from "sweetalert2";
import axios from "axios";
import swal from "sweetalert";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import { FaSearch, FaTrash, FaPen, FaCalendar } from "react-icons/fa";
function Addproduct() {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const type = location.state?.product || "";
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);

  const [selectedImages, setSelectedImages] = useState("");
  const navigate = useNavigate();
  const [filename, setFilename] = useState("");
  const [resolutionDetails, setResolutionDetails] = useState({
    battery_capacity: "",
    biometricsecurity: "",
    brand: "",
    cancelable: "",
    category: "",
    category_id: "",
    cellulartechnology: "",
    cod: "",
    connectivitytechnology: "",
    connectortype: "",
    description: "",
    devicefeatures: "",
    displaytype: "",
    gps: "",
    id: "",
    includedcomponents: "",
    instock_text: "",
    itemweight: "",
    made_in: "",
    offer_status: "",
    offer_type: "",
    offer_value: "",
    operatingsystem: "",
    phonehumaninterface: "",
    phoneresolution: "",
    photosensorresolution: "",
    price_with_tax: "",
    price_without_tax: "",
    pro_price: "",
    processor_speed: "",
    product_code: "",
    product_features: "",
    product_image: "",
    product_information: "",
    product_name: "",
    product_starts: "",
    product_status: "",
    warranty: "",
    product_stock: "",
    product_type: "",
    ramsize: "",
    refreshrate: "",
    returnable: "",
    review_count: "",
    screensize: "",
    sensorresolution: "",
    shootingmodes: "",
    simcardsize: "",
    simcardslotcount: "",
    storage_capacity: "",
    tags: "",
    tax: "",
    unit: "",
    videocapture: "",
    shortby: "",
    camera_resolution: "",
  });
  console.log("first", resolutionDetails);
  useEffect(() => {
    if (location.state && location.state.product) {
      setId(location.state.product);
    } else {
      console.error("ID not found in location state");
    }
    setFilename(resolutionDetails.product_image);
  }, [location.state]);

  const [selectedOption, setSelectedOption] = useState("limited");
  const [categoriesload, setCategoriesload] = useState([]);
  const [brandload, setBrandload] = useState([]);
  const [unitload, setUnitLoad] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [options, setOptions] = useState([]);
  const [mrpprice, setMrpPrice] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(0);
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState("");
  const [discount, setDiscount] = useState("");
  const [processorspeed, setProcessorspeed] = useState([]);
  const [batterycapacity, setBatterycapacity] = useState([]);
  const [gps, setgps] = useState([]);
  const [cameraresolution, setCameraresolution] = useState([]);
  const [phoneresolution, setPhoneresolution] = useState([]);
  const [refreshrate, setRefreshrate] = useState([]);
  const [ramsize, setRamsize] = useState([]);
  const [sensorresolution, setSensorresolution] = useState([]);
  const [cellulartechnology, setCellulartechnology] = useState([]);
  const [simcardsize, setSimcardsize] = useState([]);

  const [screensize, setScreensize] = useState([]);

  const [simcardslotcount, setSimcardslotcount] = useState([]);

  const [shooingmode, setShootingmood] = useState([]);
  const [os, setOs] = useState([]);

  const [interfaceinput, setInterfaseinput] = useState([]);
  const [cellularphone, setCellularphone] = useState([]);
  const [videocapture, setVideocapture] = useState([]);
  const [itemweight, setItemweight] = useState([]);
  const [includedcomponents, setIncludedcomponents] = useState([]);
  const [biometricsecurityfeature, setBiometricsecurityfeature] = useState([]);
  const [connectortype, setConnectortype] = useState([]);

  const [shortby, setShortby] = useState([]);
  const [devicefeatures, setDevicefeatures] = useState([]);
  const [displaytype, setDisplaytype] = useState([]);
  const [connectivitytechnology, setConnectivitytechnology] = useState([]);
  const [madeinload, setmadeinload] = useState([]);
  const [proinfo, setproinfo] = useState(false);
  const fileInputRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [tax, setTax] = useState("");
  const [unit, setUnit] = useState("");

  const [productType, setProductType] = useState("");
  const [madeIn, setMadeIn] = useState("");
  const [tags, setTags] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [productStock, setProductStock] = useState("");
  const [warranty, setWarranty] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [productStatus, setProductStatus] = useState("Approved");
  const [isReturnable, setIsReturnable] = useState(true);

  const [isCancelable, setIsCancelable] = useState(true);
  const [isCODAllowed, setIsCODAllowed] = useState(true);
  const [totalAllowedQuantity, setTotalAllowedQuantity] = useState("");
  const [productInfo, setProductInfo] = useState({
    name: "",
    value: "",
  });
  const [description, setDescription] = useState("");
  const [productFeatures, setProductFeatures] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  // console.log("selectedCategory", selectedCategory);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedcellstorage, setSelectedcellstorage] = useState([]);

  const [selectedprocessorspeed, setSelectedprocessorspeed] = useState([]);
  const [selectedbatterycapacity, setSelectedbatterycapacity] = useState([]);

  const [selectedgps, setSelectedgps] = useState(null);
  const [selectedcameraresolution, setSelectedcameraresolution] =
    useState(null);
  const [selectedphoneresolution, setSelectedphoneresolution] = useState(null);
  const [selectedrefreshrate, setSelectedrefreshrate] = useState(null);
  const [selectedramsize, setSelectedramsize] = useState(null);
  const [selectedsensorresolution, setSelectedsensorresolution] =
    useState(null);
  const [selectedshootingmodes, setSelectedshootingmodes] = useState(null);
  const [selectedcellulartechnology, setSelectedcellulartechnology] =
    useState(null);
  const [selectedsimcardsize, setSelectedsimcardsize] = useState(null);
  const [selectedoperatingsystem, setSelectedoperatingsystem] = useState(null);
  const [selectedphonehumaninterface, setSelectedphonehumaninterface] =
    useState(null);
  const [selectedphotosensorresolution, setSelectedphotosensorresolution] =
    useState(null);
  const [selectedvideocapture, setSelectedvideocapture] = useState(null);
  const [selecteditemweight, setSelecteditemweight] = useState(null);
  const [selectedincludedcomponents, setSelectedincludedcomponentst] =
    useState(null);

  const [selectedbiometricsecurity, setSelectedbiometricsecurity] =
    useState(null);
  const [selectedconnectortype, setSelectedconnectortype] = useState(null);
  const [selectedconnectivitytechnology, setSelectedconnectivitytechnology] =
    useState(null);
  const [selectedscreensize, setSelectedscreensize] = useState(null);
  const [selecteddisplaytype, setSelecteddisplaytype] = useState(null);
  const [selecteddevicefeatures, setSelecteddevicefeatures] = useState(null);
  const [selectedsimcardslotcount, setSelectedsimcardslotcount] =
    useState(null);
  const [selectedshortby, setSelectedshortby] = useState(null);
  const [variants, setVariants] = useState([]);
  const [variantValues, setVariantValues] = useState({});
  const [tableData, setTableData] = useState([]);
  console.log({ tableData: tableData });
  const [taxOptions, setTaxOptions] = useState([]);
  const [showVariations, setShowVariations] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [variantOptions, setVariantOptions] = useState([]);

  const [subCategories, setSubCategories] = useState([]);

  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [variantType, setVariantType] = useState("");
  const [variantSize, setVariantSize] = useState("");

  const [variantImage, setVariantImage] = useState("");
  const [variantStock, setVariantStock] = useState("");
  const [variantStatus, setVariantStatus] = useState("");
  const [offerStatus, setOfferStatus] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [instockText, setInstockText] = useState("");
  const [variantStar, setVariantStar] = useState("");
  const [reviewCount, setReviewCount] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [productId, setProductId] = useState("");
  const [colour, setColour] = useState("");

  const [storage, setStorage] = useState("");

  const [product, setProduct] = useState(false);
  const [varientId, setVarientId] = useState("");

  const [varprice, setVarPrice] = useState("");
  const [varientImages, setVarientImages] = useState([]);

  const [show, setShow] = useState(false);

  const [croppedImage, setCroppedImage] = useState([]);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      variantType,
      variantSize,
      variantImage,
      variantStock,
      variantStatus,
      offerStatus,
      discountedPrice,
      price,
      instockText,
      variantStar,
      reviewCount,
      createdAt,
    };
  };
  const handleImageUpload = (e) => {
    setVarientImages(e.target.files);
  };
  const handleRadioChange = (status) => {
    setProductStatus(status);
    setResolutionDetails((prevDetails) => ({
      ...prevDetails,
      product_status: status, // Ensure resolutionDetails is also updated
    }));
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const addVariant = () => {
    setVariants([...variants, {}]);
  };

  const handleRemoveVariant = (indexToRemove) => {
    setVariants((prevVariants) =>
      prevVariants.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    setSelectedImages(selectedImage);
    const files = Array.from(e.target.files); // Convert FileList to an array
    setCroppedImage(files);
    // const maxSize = 140 * 1024; // 140KB in bytes

    // if (selectedImage) {
    //   if (selectedImage.size < maxSize) {
    //     // File size must be less than 140KB
    //     setCroppedImage(selectedImage);
    //     setErrorMessage(""); // Clear error message if file is valid

    //     // Extract and display the filename
    //     const fileName = selectedImage.name;
    //     setFilename(fileName); // Assuming you have a state for filename
    //   } else {
    //     setErrorMessage("The file size must be less than 140KB.");
    //     setFilename(""); // Clear filename if file is invalid
    //   }
    // } else {
    //   setErrorMessage("No file selected.");
    //   setFilename(""); // Clear filename if no file is selected
    // }
  };

  useEffect(() => {
    // Dataget();
    Brandget();
    Unitget();
    Madeinget();
    if (id) {
      handleEdit();
    }
  }, [id]);

  const handleEdit = async () => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://arasurdigital.com/admin/product.php?`
        );

        if (response.ok) {
          const resolutions = await response.json();
          const resolutionDetails = resolutions.find((item) => item.id === id);

          if (resolutionDetails) {
            setResolutionDetails(resolutionDetails);
          } else {
            console.error("Resolution details not found for ID:", id);
          }
        } else {
          console.error("Failed to fetch phone resolution");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const delayLoading = new Promise((resolve) => setTimeout(resolve, 3000));
    await Promise.all([fetchData(), delayLoading]);

    setLoading(false);
  };

  const Dataget = async () => {
    const data = await fetch(
      "https://arasurdigital.com/admin/categoriesmaster.php?"
    );
    const response = await data.json();
    setCategoriesload(response);
  };

  const Unitget = async () => {
    const data = await fetch(
      "https://arasurdigital.com/admin/addunit.php?"
    );
    const response = await data.json();
    setUnitLoad(response);
  };

  const Brandget = async () => {
    const data = await fetch(
      "https://arasurdigital.com/admin/brand.php?status=1"
    );
    const response = await data.json();
    setBrandload(response);
  };

  const Madeinget = async () => {
    const data = await fetch(
      "https://restcountries.com/v3.1/independent?status=true"
    );
    const response = await data.json();
    setmadeinload(response);
  };

  const handleInfo = () => {
    setproinfo(!proinfo);
  };

  useEffect(() => {
    fetch("https://arasurdigital.com/admin/addtax.php?status=1")
      .then((response) => response.json())
      .then((data) => {
        setTaxOptions(data);
      })
      .catch((error) => {
        console.error("Error fetching tax options:", error);
      });
  }, []);

  // useEffect(() => {
  //   fetchCatData();
  // }, []);

  const fetchOptions = async () => {
    try {
      // console.log({ selectedCategory: selectedCategory });
      const response = await axios.get(
        `https://arasurdigital.com/admin/cellularmemorystoragecapacity.php?category_id=${selectedCategory}`
      );
      const cellularmemorystoragecapacity = response.data;
      const categoryDetails = cellularmemorystoragecapacity.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );

      // Assuming the data structure for the options should have label and value properties
      // const option = categoryDetails.map((item) => ({
      //     label: item.storagecapacity,
      //     value: item.id,
      // }));
      // console.log({options:options})

      setOptions(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProcessorspeed = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/processorspeed.php?category_id=${selectedCategory}`
      );
      const processorspeedData = response.data;
      const categoryDetails = processorspeedData.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );
      // console.log("fetchProcessorspeed", categoryDetails);

      // const option = categoryDetails.map((item) => ({
      //     label: item.processor_speed,
      //     value: item.id,
      // }));
      setProcessorspeed(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchbatterycapacity = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/batterycapacity.php?category_id=${selectedCategory}`
      );
      const processorspeedData = response.data;
      const categoryDetails = processorspeedData.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.battery_capacity,
      //     value: item.id,
      // }));
      setBatterycapacity(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchgps = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/gps.php?category_id=${selectedCategory}`
      );

      const gpsData = response.data;
      const categoryDetails = gpsData.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.gps,
      //     value: item.id,
      // }));
      setgps(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchcameraresolution = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/cameraresolution.php?category_id=${selectedCategory}`
      );

      const cameraresolution = response.data;
      const categoryDetails = cameraresolution.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.camera_resolution,
      //     value: item.id,
      // }));
      setCameraresolution(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchphoneresolution = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/phoneresolution.php?category_id=${selectedCategory}`
      );

      const phoneresolutions = response.data;
      const categoryDetails = phoneresolutions.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.phone_resolution,
      //     value: item.id,
      // }));
      setPhoneresolution(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchrefreshrate = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/refreshrate.php?category_id=${selectedCategory}`
      );

      const refreshrates = response.data;
      const categoryDetails = refreshrates.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );
      // const option = categoryDetails.map((item) => ({
      //     label: item.refresh_rate,
      //     value: item.id,
      // }));
      setRefreshrate(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchramsize = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/ramsize.php?category_id=${selectedCategory}`
      );

      const ramsizes = response.data;
      const categoryDetails = ramsizes.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.ram_size,
      //     value: item.id,
      // }));
      setRamsize(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchsensorresolution = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/Sensorresolution.php?category_id=${selectedCategory}`
      );

      const sensorResolutions = response.data;
      // console.log({ sensorResolutions: sensorResolutions });
      const categoryDetails = sensorResolutions.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );
      // const option = categoryDetails.map((item) => ({
      //     label: item.sensor_resolution,
      //     value: item.id,
      // }));
      setSensorresolution(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchshootingmood = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/shootingmodes.php?category_id=${selectedCategory}`
      );

      const shootingModes = response.data;
      const categoryDetails = shootingModes.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );
      // console.log({ categoryDetails: categoryDetails })
      // const option = categoryDetails.map((item) => ({
      //     label: item.shooting_modes,
      //     value: item.id,
      // }));
      setShootingmood(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchcellulartechnology = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/Cellulartechnology.php?category_id=${selectedCategory}`
      );

      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );
      // const option = categoryDetails.map((item) => ({
      //     label: item.Cellular_technology,
      //     value: item.id,
      // }));
      setCellulartechnology(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchsimcardsize = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/simcard.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.sim_card,
      //     value: item.id,
      // }));

      setSimcardsize(categoryDetails);
    } catch (error) {
      console.error("Error fetching SIM card sizes:", error);
    }
  };

  const fetchos = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/os.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.os,
      //     value: item.id,
      // }));
      // setOs(response.categoryDetails);
      setOs(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchinterfaceinput = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/interfaceinput.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.interface_input,
      //     value: item.id,
      // }));
      setInterfaseinput(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchcellularphone = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/cellularphone.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );
      // const option = categoryDetails.map((item) => ({
      //     label: item.cellularphone,
      //     value: item.id,
      // }));
      setCellularphone(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchvideocapture = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/videocapture.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );
      // const option = categoryDetails.map((item) => ({
      //     label: item.video_capture,
      //     value: item.id,
      // }));
      setVideocapture(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchitemweight = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/itemweight.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.item_weight,
      //     value: item.id,
      // }));
      setItemweight(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchincludedcomponents = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/includedcomponents.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.included_components,
      //     value: item.id,
      // }));

      setIncludedcomponents(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchbiometricsecurityfeature = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/biometric_securityfeature.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.biometric_securityfeature,
      //     value: item.id,
      // }));
      setBiometricsecurityfeature(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchconnectortypes = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/connectortype.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );
      // const option = categoryDetails.map((item) => ({
      //     label: item.connector_type,
      //     value: item.id,
      // }));
      setConnectortype(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchscreensize = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/screensize.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.screen_size,
      //     value: item.id,
      // }));
      setScreensize(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchconnectivitytechnology = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/connectivitytechnology.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.phone_connectivity,
      //     value: item.id,
      // }));
      setConnectivitytechnology(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchdisplaytype = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/displaytype.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );
      // console.log({categoryDetails:categoryDetails})
      // const option = categoryDetails.map((item) => ({
      //     label: item.display_type,
      //     value: item.id,
      // }));
      setDisplaytype(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchdevicefeatures = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/device_features.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );
      // console.log({categoryDetails:categoryDetails})
      // const option = categoryDetails.map((item) => ({
      //     label: item.device_features,
      //     value: item.id,
      // }));
      setDevicefeatures(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchsimcardslotcount = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/simcard_slotcount.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );

      // const option = categoryDetails.map((item) => ({
      //     label: item.simcard_slotcount,
      //     value: item.id,
      // }));
      // console.log({ fetchsimcardslotcount: categoryDetails })
      setSimcardslotcount(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchshortby = async () => {
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/shortby.php?category_id=${selectedCategory}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(selectedCategory))
      );
      // console.log({ categoryDetails: categoryDetails });
      // const option = categoryDetails.map((item) => ({
      //     label: item.short_by,
      //     value: item.id,
      // }));
      setShortby(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      const fetchAllAttributes = async () => {
        await Promise.all([
          fetchgps(selectedCategory),
          fetchramsize(selectedCategory),
          fetchphoneresolution(selectedCategory),
          fetchcameraresolution(selectedCategory),
          fetchProcessorspeed(selectedCategory),
          fetchbatterycapacity(selectedCategory),
          fetchshootingmood(selectedCategory),
          fetchOptions(selectedCategory),
          fetchsimcardsize(selectedCategory),
          fetchrefreshrate(selectedCategory),
          fetchcellulartechnology(selectedCategory),
          fetchsensorresolution(selectedCategory),
          fetchinterfaceinput(selectedCategory),
          fetchos(selectedCategory),
          fetchshortby(selectedCategory),
          fetchcellularphone(selectedCategory),
          fetchvideocapture(selectedCategory),
          fetchitemweight(selectedCategory),
          fetchconnectivitytechnology(selectedCategory),
          fetchincludedcomponents(selectedCategory),
          fetchbiometricsecurityfeature(selectedCategory),
          fetchconnectortypes(selectedCategory),
          fetchscreensize(selectedCategory),
          fetchdisplaytype(selectedCategory),
          fetchdevicefeatures(selectedCategory),
          fetchsimcardslotcount(selectedCategory),
        ]);
      };

      fetchAllAttributes();
    }
  }, [selectedCategory]);

  const fetchCatData = async () => {
    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/categoriesmaster.php"
      );
      const data = await response.json();
      setCategories(data);

      setSelectedVariant(data);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleCategoryChange = (e) => {
    try {
      if (!e || !e.target) {
        throw new Error("Event object or its target is undefined");
      }

      const categoryId = e.target.value;
      // setSubCategories([]);

      if (id) {
        setResolutionDetails((prevDetails) => ({
          ...prevDetails,
          category: categoryId,
        }));
      }

      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        category_id: "",
      }));

      setSelectedCategory(categoryId);

      const selectedCategory = categories.find(
        (category) => category.id === categoryId
      );

      if (selectedCategory) {
        setSelectedCategoryName(selectedCategory.id);

        fetch(
          `https://arasurdigital.com/admin/categories.php?parent_category=${categoryId}`
        )
          .then((response) => response.json())
          .then((data) => {
            setSubCategories(data);
          })
          .catch((error) => {
            console.error("Error fetching sub-categories:", error);
          });

        const categoryVariants = selectedCategory.variants.split(",");
        setVariants(categoryVariants || []);
        setVariantValues({});

        categoryVariants.forEach((variant) => {
          const [cl, sz] = variant.split(",");

          fetch(`https://arasurdigital.com/admin/${cl}_master.php`)
            .then((response) => response.json())
            .then((data) => {
              setVariantOptions((prevState) => ({
                ...prevState,
                [cl]: data,
              }));
            })
            .catch((error) => {
              console.error(`Error fetching ${cl} options:`, error);
            });

          fetch(`https://arasurdigital.com/admin/${sz}_master.php`)
            .then((response) => response.json())
            .then((data) => {
              setVariantOptions((prevState) => ({
                ...prevState,
                [sz]: data,
              }));
            })
            .catch((error) => {
              console.error(`Error fetching ${sz} options:`, error);
            });
        });
      } else {
        console.error("Selected category not found.");
      }
    } catch (error) {
      console.error("Error in handleCategoryChange:", error);
    }
  };

  const handleEditSelectedCategory = () => {
    const category_id = resolutionDetails?.category;
    console.log(
      "category_id",
      `https://arasurdigital.com/admin/categories.php?parent_category=${category_id}`
    );

    fetch(
      `https://arasurdigital.com/admin/categories.php?parent_category=${category_id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSubCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching sub-categories:", error);
      });
  };

  const handleEditProcessorSpeed = async () => {
    const category_id = resolutionDetails?.category_id;

    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/processorspeed.php?category_id=${category_id}`
      );
      const processorspeedData = response.data;
      const categoryDetails = processorspeedData.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setProcessorspeed(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditgps = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/gps.php?category_id=${category_id}`
      );

      const gpsData = response.data;
      const categoryDetails = gpsData.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setgps(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditramsize = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/ramsize.php?category_id=${category_id}`
      );

      const ramsizes = response.data;
      const categoryDetails = ramsizes.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setRamsize(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditphoneresolution = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/phoneresolution.php?category_id=${category_id}`
      );

      const phoneresolutions = response.data;
      const categoryDetails = phoneresolutions.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setPhoneresolution(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditcameraresolution = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/cameraresolution.php?category_id=${category_id}`
      );

      const cameraresolution = response.data;
      const categoryDetails = cameraresolution.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );
      setCameraresolution(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditbatterycapacity = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/batterycapacity.php?category_id=${category_id}`
      );
      const processorspeedData = response.data;
      const categoryDetails = processorspeedData.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setBatterycapacity(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditshootingmood = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/shootingmodes.php?category_id=${category_id}`
      );

      const shootingModes = response.data;
      const categoryDetails = shootingModes.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setShootingmood(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditOptions = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/cellularmemorystoragecapacity.php?category_id=${category_id}`
      );
      const cellularmemorystoragecapacity = response.data;
      const categoryDetails = cellularmemorystoragecapacity.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setOptions(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditsimcardsize = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/simcard.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter(
        (item) => item.category_id.split(",").map((id) => id.trim())
        // .includes(String(category_id))
      );

      setSimcardsize(categoryDetails);
    } catch (error) {
      console.error("Error fetching SIM card sizes:", error);
    }
  };
  const handleEditrefreshrate = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/refreshrate.php?category_id=${category_id}`
      );

      const refreshrates = response.data;
      const categoryDetails = refreshrates.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setRefreshrate(categoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleEditcellulartechnology = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/Cellulartechnology.php?category_id=${category_id}`
      );

      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );
      setCellulartechnology(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditsensorresolution = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/Sensorresolution.php?category_id=${category_id}`
      );

      const sensorResolutions = response.data;
      const categoryDetails = sensorResolutions.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setSensorresolution(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditinterfaceinput = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/interfaceinput.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );

      setInterfaseinput(response.data);
      setInterfaseinput(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditos = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/os.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id.split(",").map((id) => id.trim())
      );

      setOs(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditshortby = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/shortby.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );

      setShortby(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleEditcellularphone = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/cellularphone.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setCellularphone(response.data);
      setCellularphone(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditvideocapture = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/videocapture.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setVideocapture(response.data);
      setVideocapture(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEdititemweight = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/itemweight.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setItemweight(response.data);
      setItemweight(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditconnectivitytechnology = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/connectivitytechnology.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setConnectivitytechnology(response.data);
      setConnectivitytechnology(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditincludedcomponents = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/includedcomponents.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setIncludedcomponents(response.data);

      setIncludedcomponents(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditbiometricsecurityfeature = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/biometric_securityfeature.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setBiometricsecurityfeature(response.data);
      setBiometricsecurityfeature(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditconnectortypes = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/connectortype.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setConnectortype(response.data);
      setConnectortype(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditscreensize = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/screensize.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setScreensize(response.data);
      setScreensize(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditdisplaytype = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/displaytype.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setDisplaytype(response.data);
      setDisplaytype(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditdevicefeatures = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/device_features.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setDevicefeatures(response.data);
      setDevicefeatures(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  const handleEditsimcardslotcount = async () => {
    const category_id = resolutionDetails?.category_id;
    try {
      const response = await axios.get(
        `https://arasurdigital.com/admin/simcard_slotcount.php?category_id=${category_id}`
      );
      const cellularTechnology = response.data;
      const categoryDetails = cellularTechnology.filter((item) =>
        item.category_id
          .split(",")
          .map((id) => id.trim())
          .includes(String(category_id))
      );
      setSimcardslotcount(response.data);
      setSimcardslotcount(categoryDetails);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const Call = async () => {
      await fetchCatData();
    };
    Call();
  }, [resolutionDetails?.category_id]);

  useEffect(() => {
    if (resolutionDetails?.category_id && type !== null) {
      const ids = resolutionDetails.category_id;

      const handleAllEdits = async () => {
        try {
          // Ensure handleEditSelectedCategory is called first
          await handleEditSelectedCategory(resolutionDetails.category);

          // Run other functions concurrently after handleEditSelectedCategory is done
          await Promise.all([
            handleSubCategoryChange(ids),
            handleEditProcessorSpeed(ids),
            handleEditgps(ids),
            handleEditramsize(ids),
            handleEditphoneresolution(ids),
            handleEditcameraresolution(ids),
            handleEditbatterycapacity(ids),
            handleEditshootingmood(ids),
            handleEditOptions(ids),
            handleEditsimcardsize(ids),
            handleEditrefreshrate(ids),
            handleEditcellulartechnology(ids),
            handleEditsensorresolution(ids),
            handleEditinterfaceinput(ids),
            handleEditos(ids),
            handleEditshortby(ids),
            handleEditcellularphone(ids),
            handleEditvideocapture(ids),
            handleEdititemweight(ids),
            handleEditconnectivitytechnology(ids),
            handleEditincludedcomponents(ids),
            handleEditbiometricsecurityfeature(ids),
            handleEditconnectortypes(ids),
            handleEditscreensize(ids),
            handleEditdisplaytype(ids),
            handleEditdevicefeatures(ids),
            handleEditsimcardslotcount(ids),
          ]);

          // Final update for the category
          handleCategoryChange(resolutionDetails?.category);
        } catch (error) {
          console.error("Error handling updates:", error); // Add error handling if needed
        }
      };

      handleAllEdits();
    }
  }, [resolutionDetails?.category_id, type]);

  const handleSubCategoryChange = (selectedValue) => {
    // const categoryId = e.target.value;
    setSelectedSubCategoryId(selectedValue);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        category_id: selectedValue,
      }));
    }
  };

  const handleVariantChange = (variantName, selectedId) => {
    // Find the corresponding value for the selected ID
    const selectedOption = variantOptions[variantName]?.find(
      (option) => option.id === selectedId
    );
    const selectedValue = selectedOption ? selectedOption[variantName] : null;

    if (variantName === "colour") {
      setColour(selectedValue);
    } else if (variantName === "size") {
      setVariantSize(selectedValue);
    }

    setSelectedVariant((prevState) => ({
      ...prevState,
      [variantName]: selectedId,
    }));
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        description: content,
      }));
    }
  };

  const handleProductFeaturesChange = (content, delta, source, editor) => {
    setProductFeatures(content);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        product_features: content,
      }));
    }
  };

  const handleMainImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMainImage(file.name);
    } else {
      setMainImage("");
    }
  };

  const handleInputInfoChange = (e) => {
    const { name, value } = e.target;
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleRadioChangeCod = (e) => {
    const codValue = e.target.value === "Yes";
    setIsCODAllowed(codValue);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        cod: codValue ? "Yes" : "No",
      }));
    }
  };

  const handleRadioChangeRe = (e, setIsReturnable, setResolutionDetails) => {
    const { value } = e.target;
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        returnable: value,
      }));
    }
    setIsReturnable(value === "Yes");
  };

  const handleRadioChangeCan = (e, setIsCancelable, setResolutionDetails) => {
    const { value } = e.target;
    setIsCancelable(value === "Yes");
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        cancelable: value,
      }));
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Allow only numerical values
    if (/^\d*$/.test(inputValue)) {
      setTotalAllowedQuantity(inputValue);
    }
  };

  const handleRadioChangeapp = (e) => {
    setIsReturnable(e.target.value === "Approved");
  };

  const handleProductStatusChange = (event) => {
    setProductStatus(event.target.value);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        made_in: event.target.value,
      }));
    }
  };

  // const handleInputInfoChangecell = (selectedOptions) => {
  //   const cell = selectedOptions
  //     ? selectedOptions.map((option) => option.value)
  //     : [];
  //   setSelectedcellstorage(cell);

  //   if (id) {
  //     setResolutionDetails((prevDetails) => ({
  //       ...prevDetails,
  //       storage_capacity: cell,
  //     }));
  //   }
  // };

  const handleInputInfoChangecell = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedcellstorage(selectedOptions || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        storage_capacity: selectedValues.join(","),
      }));
    }
  };
  const handleInputInfoChangeprocessorspeed = (selectedprocessorspeed) => {
    const cell = selectedprocessorspeed
      ? selectedprocessorspeed.map((processorspeed) => processorspeed.value)
      : [];
    setSelectedprocessorspeed(selectedprocessorspeed || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        processor_speed: cell.join(","),
      }));
    }
  };
  const handleInputInfoChangebatterycapacity = (selectedbatterycapacity) => {
    // console.log("batterycapacity", selectedbatterycapacity);

    const cell = selectedbatterycapacity
      ? selectedbatterycapacity.map((batterycapacity) => batterycapacity.value)
      : [];
    setSelectedbatterycapacity(selectedbatterycapacity || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        battery_capacity: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangegps = (selectedgps) => {
    const cell = selectedgps ? selectedgps.map((gps) => gps.value) : [];
    setSelectedgps(selectedgps || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        gps: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangecameraresolution = (selectedcameraresolution) => {
    const cell = selectedcameraresolution
      ? selectedcameraresolution.map(
          (cameraresolution) => cameraresolution.value
        )
      : [];
    setSelectedcameraresolution(selectedcameraresolution || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        camera_resolution: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangephoneresolution = (selectedphoneresolution) => {
    const cell = selectedphoneresolution
      ? selectedphoneresolution.map((phoneresolution) => phoneresolution.value)
      : [];
    setSelectedphoneresolution(selectedphoneresolution || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        phoneresolution: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangerefreshrate = (selectedrefreshrate) => {
    const cell = selectedrefreshrate
      ? selectedrefreshrate.map((refreshrate) => refreshrate.value)
      : [];
    setSelectedrefreshrate(selectedrefreshrate || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        refreshrate: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangeramsize = (selectedramsize) => {
    const cell = selectedramsize
      ? selectedramsize.map((ramsize) => ramsize.value)
      : [];
    setSelectedramsize(selectedramsize || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        ramsize: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangesensorresolution = (selectedsensorresolution) => {
    const cell = selectedsensorresolution
      ? selectedsensorresolution.map(
          (sensorresolution) => sensorresolution.value
        )
      : [];
    setSelectedsensorresolution(selectedsensorresolution || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        sensorresolution: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangeshootingmodes = (selectedshootingmodes) => {
    const cell = selectedshootingmodes
      ? selectedshootingmodes.map((shootingmodes) => shootingmodes.value)
      : [];
    setSelectedshootingmodes(selectedshootingmodes || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        shootingmodes: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangecellulartechnology = (
    selectedcellulartechnology
  ) => {
    const cell = selectedcellulartechnology
      ? selectedcellulartechnology.map(
          (cellulartechnology) => cellulartechnology.value
        )
      : [];
    setSelectedcellulartechnology(selectedcellulartechnology || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        cellulartechnology: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangesimcardsize = (selectedsimcardsize) => {
    const cell = selectedsimcardsize
      ? selectedsimcardsize.map((simcardsize) => simcardsize.value)
      : [];
    setSelectedsimcardsize(selectedsimcardsize || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        simcardsize: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangeoperatingsystem = (selectedoperatingsystem) => {
    const cell = selectedoperatingsystem
      ? selectedoperatingsystem.map((operatingsystem) => operatingsystem.value)
      : [];
    setSelectedoperatingsystem(selectedoperatingsystem || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        operatingsystem: cell.join(","),
      }));
    }
  };
  const handleInputInfoChangephonehumaninterface = (
    selectedphonehumaninterface
  ) => {
    const cell = selectedphonehumaninterface
      ? selectedphonehumaninterface.map(
          (phonehumaninterface) => phonehumaninterface.value
        )
      : [];
    setSelectedphonehumaninterface(selectedphonehumaninterface || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        phonehumaninterface: cell.join(","),
      }));
    }
  };
  const handleInputInfoChangephotosensorresolution = (
    selectedphotosensorresolution
  ) => {
    const cell = selectedphotosensorresolution
      ? selectedphotosensorresolution.map(
          (photosensorresolution) => photosensorresolution.value
        )
      : [];
    setSelectedphotosensorresolution(selectedphotosensorresolution || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        photosensorresolution: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangevideocapture = (selectedvideocapture) => {
    const cell = selectedvideocapture
      ? selectedvideocapture.map((videocapture) => videocapture.value)
      : [];
    setSelectedvideocapture(selectedvideocapture || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        videocapture: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangeitemweight = (selecteditemweight) => {
    const cell = selecteditemweight
      ? selecteditemweight.map((itemweight) => itemweight.value)
      : [];
    setSelecteditemweight(selecteditemweight || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        itemweight: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangeincludedcomponents = (
    selectedincludedcomponents
  ) => {
    const cell = selectedincludedcomponents
      ? selectedincludedcomponents.map(
          (includedcomponents) => includedcomponents.value
        )
      : [];
    setSelectedincludedcomponentst(selectedincludedcomponents || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        includedcomponents: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangebiometricsecurity = (
    selectedbiometricsecurity
  ) => {
    const cell = selectedbiometricsecurity
      ? selectedbiometricsecurity.map(
          (biometricsecurity) => biometricsecurity.value
        )
      : [];
    setSelectedbiometricsecurity(selectedbiometricsecurity || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        biometricsecurity: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangeconnectortype = (selectedbiometricsecurity) => {
    const cell = selectedbiometricsecurity
      ? selectedbiometricsecurity.map(
          (biometricsecurity) => biometricsecurity.value
        )
      : [];
    setSelectedconnectortype(selectedbiometricsecurity || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        connectortype: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangeconnectivitytechnologye = (
    selectedconnectivitytechnology
  ) => {
    const cell = selectedconnectivitytechnology
      ? selectedconnectivitytechnology.map(
          (connectivitytechnology) => connectivitytechnology.value
        )
      : [];
    setSelectedconnectivitytechnology(selectedconnectivitytechnology || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        connectivitytechnology: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangescreensize = (selectedscreensize) => {
    const cell = selectedscreensize
      ? selectedscreensize.map((screensize) => screensize.value)
      : [];
    setSelectedscreensize(selectedscreensize || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        screensize: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangedisplaytypee = (selecteddisplaytype) => {
    const cell = selecteddisplaytype
      ? selecteddisplaytype.map((displaytype) => displaytype.value)
      : [];
    setSelecteddisplaytype(selecteddisplaytype || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        displaytype: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangedevicefeatures = (selecteddevicefeatures) => {
    const cell = selecteddevicefeatures
      ? selecteddevicefeatures.map((devicefeatures) => devicefeatures.value)
      : [];
    setSelecteddevicefeatures(selecteddevicefeatures || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        devicefeatures: cell.join(","),
      }));
    }
  };

  const handleInputInfoChangesimcardslotcount = (selectedsimcardslotcount) => {
    const cell = selectedsimcardslotcount
      ? selectedsimcardslotcount.map(
          (simcardslotcount) => simcardslotcount.value
        )
      : [];
    setSelectedsimcardslotcount(selectedsimcardslotcount || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        simcardslotcount: cell.join(","),
      }));
    }
  };
  const handleInputInfoChangeshortby = (selectedshortby) => {
    const cell = selectedshortby
      ? selectedshortby.map((shortby) => shortby.value)
      : [];
    setSelectedshortby(selectedshortby || []);
    if (id) {
      setResolutionDetails((prevDetails) => ({
        ...prevDetails,
        shortby: cell.join(","),
      }));
    }
  };

  function generateRandomProductCode(productName) {
    const randomNumbers = Math.floor(Math.random() * 1000);
    const productCode = `${productName.toUpperCase()}${randomNumbers}`;
    return productCode;
  }

  const productCode = generateRandomProductCode(productName);
  const handleInsertProduct = () => {
    if (
      !productName ||
      !productType ||
      !selectedCategory ||
      !selectedCountry ||
      !price ||
      !unit ||
      !tags ||
      !croppedImage ||
      !productFeatures ||
      !description ||
      !selectedSubCategoryId ||
      !warranty ||
      !brand
    ) {
      swal("warning", "Please enter required fields ", "warning");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("product_type", productType);
    formData.append("product_name", productName);
    formData.append("product_code", productCode);
    formData.append("tags", tags);
    formData.append("pro_price", price);
    formData.append("discount", discount);
    formData.append("mrpprice", mrpprice);
    formData.append("product_status", productStatus);
    formData.append("brand", brand);
    formData.append("description", description);
    formData.append("made_in", selectedCountry);
    formData.append("category", selectedCategoryName);
    formData.append("category_id", selectedSubCategoryId);
    formData.append("tax", tax);
    formData.append("unit", unit);
    formData.append("product_stock", productStock);
    formData.append("closeing_stock", productStock);
    formData.append("warranty", warranty);
    formData.append("product_status", productStatus);
    formData.append("product_features", productFeatures);
    formData.append("product_information", JSON.stringify(productInfo));
    Array.from(croppedImage).forEach((imageFile, index) => {
      formData.append(`product_image${index}`, imageFile);
    });
    // formData.append("product_image", croppedImage);
    formData.append("returnable", isReturnable ? "Yes" : "No");
    formData.append("cancelable", isCancelable ? "Yes" : "No");
    formData.append("cod", isCODAllowed ? "Yes" : "No");

    // Provide default empty arrays if the selected* variables are null or undefined
    formData.append(
      "storage_capacity",
      selectedcellstorage && selectedcellstorage.length > 0
        ? selectedcellstorage.map((product) => product.value)
        : 0
    );

    formData.append(
      "processor_speed",
      selectedprocessorspeed && selectedprocessorspeed.length > 0
        ? selectedprocessorspeed.map((product) => product.value)
        : 0
    );
    formData.append(
      "gps",
      selectedgps && selectedgps.length > 0
        ? selectedgps.map((product) => product.value)
        : 0
    );
    formData.append(
      "phoneresolution",
      selectedphoneresolution && selectedphoneresolution.length > 0
        ? selectedphoneresolution.map((product) => product.value)
        : 0
    );
    formData.append(
      "refreshrate",
      selectedrefreshrate && selectedrefreshrate.length > 0
        ? selectedrefreshrate.map((product) => product.value)
        : 0
    );
    formData.append(
      "ramsize",
      selectedramsize && selectedramsize.length > 0
        ? selectedramsize.map((product) => product.value)
        : 0
    );
    formData.append(
      "sensorresolution",
      selectedsensorresolution && selectedsensorresolution.length > 0
        ? selectedsensorresolution.map((product) => product.value)
        : 0
    );
    formData.append(
      "shootingmodes",
      selectedshootingmodes && selectedshootingmodes.length > 0
        ? selectedshootingmodes.map((product) => product.value)
        : 0
    );
    formData.append(
      "cellulartechnology",
      selectedcellulartechnology && selectedcellulartechnology.length > 0
        ? selectedcellulartechnology.map((product) => product.value)
        : 0
    );
    formData.append(
      "simcardsize",
      selectedsimcardsize && selectedsimcardsize.length > 0
        ? selectedsimcardsize.map((product) => product.value)
        : 0
    );
    formData.append(
      "operatingsystem",
      selectedoperatingsystem && selectedoperatingsystem.length > 0
        ? selectedoperatingsystem.map((product) => product.value)
        : 0
    );
    formData.append(
      "phonehumaninterface",
      selectedphonehumaninterface && selectedphonehumaninterface.length > 0
        ? selectedphonehumaninterface.map((product) => product.value)
        : 0
    );
    formData.append(
      "photosensorresolution",
      selectedphotosensorresolution && selectedphotosensorresolution.length > 0
        ? selectedphotosensorresolution.map((product) => product.value)
        : 0
    );
    formData.append(
      "videocapture",
      selectedvideocapture && selectedvideocapture.length > 0
        ? selectedvideocapture.map((product) => product.value)
        : 0
    );
    formData.append(
      "itemweight",
      selecteditemweight && selecteditemweight.length > 0
        ? selecteditemweight.map((product) => product.value)
        : 0
    );
    formData.append(
      "includedcomponents",
      selectedincludedcomponents && selectedincludedcomponents.length > 0
        ? selectedincludedcomponents.map((product) => product.value)
        : 0
    );
    formData.append(
      "biometricsecurity",
      selectedbiometricsecurity && selectedbiometricsecurity.length > 0
        ? selectedbiometricsecurity.map((product) => product.value)
        : 0
    );
    formData.append(
      "connectortype",
      selectedconnectortype && selectedconnectortype.length > 0
        ? selectedconnectortype.map((product) => product.value)
        : 0
    );
    formData.append(
      "connectivitytechnology",
      selectedconnectivitytechnology &&
        selectedconnectivitytechnology.length > 0
        ? selectedconnectivitytechnology.map((product) => product.value)
        : 0
    );
    formData.append(
      "screensize",
      selectedscreensize && selectedscreensize.length > 0
        ? selectedscreensize.map((product) => product.value)
        : 0
    );
    formData.append(
      "displaytype",
      selecteddisplaytype && selecteddisplaytype.length > 0
        ? selecteddisplaytype.map((product) => product.value)
        : 0
    );
    formData.append(
      "devicefeatures",
      selecteddevicefeatures && selecteddevicefeatures.length > 0
        ? selecteddevicefeatures.map((product) => product.value)
        : 0
    );
    formData.append(
      "simcardslotcount",
      selectedsimcardslotcount && selectedsimcardslotcount.length > 0
        ? selectedsimcardslotcount.map((product) => product.value)
        : 0
    );
    formData.append(
      "battery_capacity",
      selectedbatterycapacity && selectedbatterycapacity.length > 0
        ? selectedbatterycapacity.map((product) => product.value)
        : 0
    );
    formData.append(
      "shortby",
      selectedshortby && selectedshortby.length > 0
        ? selectedshortby.map((product) => product.value)
        : 0
    );
    formData.append(
      "camera_resolution",
      selectedcameraresolution && selectedcameraresolution.length > 0
        ? selectedcameraresolution.map((product) => product.value)
        : 0
    );

    // Debugging log
    for (let [key, value] of formData.entries()) {
      // console.log(`${key}:`, value);
    }

    fetch("https://arasurdigital.com/admin/product.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Failed to insert");
        }
      })
      .then((data) => {
        const lastInsertedId = data.last_id; // Get the last inserted ID from the response
        swal({
          title: "Success!",
          text: `Product inserted successfully. Product ID: ${lastInsertedId}`,
          icon: "success",
          button: {
            text: "OK",
            className: "swal-button--success", // Optional custom class for the button
          },
          timer: 3000, // Auto-close the alert after 3 seconds
        });
        setIsSaved(true);
        setProductId(lastInsertedId);
        setProduct(true);
        stockInsertproduct(lastInsertedId);
      })
      .catch((error) => {
        console.log("An error occurred while sending data: " + error.message);
      })
      .finally(() => {
        setIsLoading(false); // Re-enable the button after saving finishes
      });
  };

  const handleupdate = async () => {
    if (
      !resolutionDetails.product_name ||
      !resolutionDetails.product_type ||
      !resolutionDetails?.category_id ||
      !resolutionDetails.made_in ||
      !resolutionDetails.pro_price ||
      !resolutionDetails.unit ||
      !resolutionDetails.tags ||
      !resolutionDetails.product_image ||
      !resolutionDetails.product_features ||
      !resolutionDetails.description ||
      !resolutionDetails.category ||
      !resolutionDetails.warranty ||
      !resolutionDetails.brand
    ) {
      swal("warning", "Please enter required fields ", "warning");
      return;
    }
    const formData = new FormData();
    // resolutionDetails single value is directly set
    formData.append("id", resolutionDetails.id);
    formData.append("product_type", resolutionDetails.product_type);
    formData.append("product_name", resolutionDetails.product_name);
    formData.append("product_code", resolutionDetails.product_code);
    formData.append("tags", resolutionDetails.tags);
    formData.append("pro_price", resolutionDetails.pro_price);
    formData.append("discount", resolutionDetails.discount);
    formData.append("mrpprice", resolutionDetails.mrpprice);
    formData.append("brand", resolutionDetails.brand);
    formData.append("description", resolutionDetails.description);
    formData.append("made_in", resolutionDetails.made_in);
    formData.append("category", resolutionDetails.category);
    formData.append("category_id", resolutionDetails.category_id);
    formData.append("tax", resolutionDetails.tax);
    formData.append("warranty", resolutionDetails.warranty);
    formData.append("unit", resolutionDetails.unit);
    formData.append("product_features", resolutionDetails.product_features);
    formData.append(
      "product_information",
      JSON.stringify(resolutionDetails.product_information)
    );
    formData.append("returnable", resolutionDetails.returnable);
    formData.append("cancelable", resolutionDetails.cancelable);
    formData.append("cod", resolutionDetails.cod);
    formData.append("product_status", resolutionDetails.product_status);

    //Total resolutionDetails any value array is
    const ArrayField = (field, data) => {
      if (Array.isArray(data)) {
        formData.append(
          field,
          data.length ? data.map((item) => item.value).join(",") : ""
        );
      } else {
        formData.append(field, data || "");
      }
    };

    // Utility function to handle empty or undefined values
    const getArrayValue = (value) => (value && value.length > 0 ? value : 0);

    ArrayField(
      "battery_capacity",
      getArrayValue(resolutionDetails.battery_capacity)
    );
    ArrayField(
      "biometricsecurity",
      getArrayValue(resolutionDetails.biometricsecurity)
    );
    ArrayField(
      "storage_capacity",
      getArrayValue(resolutionDetails.storage_capacity)
    );
    ArrayField(
      "processor_speed",
      getArrayValue(resolutionDetails.processor_speed)
    );
    ArrayField("gps", getArrayValue(resolutionDetails.gps));
    ArrayField(
      "phoneresolution",
      getArrayValue(resolutionDetails.phoneresolution)
    );
    ArrayField("refreshrate", getArrayValue(resolutionDetails.refreshrate));
    ArrayField("ramsize", getArrayValue(resolutionDetails.ramsize));
    ArrayField(
      "sensorresolution",
      getArrayValue(resolutionDetails.sensorresolution)
    );
    ArrayField("shootingmodes", getArrayValue(resolutionDetails.shootingmodes));
    ArrayField(
      "cellulartechnology",
      getArrayValue(resolutionDetails.cellulartechnology)
    );
    ArrayField("simcardsize", getArrayValue(resolutionDetails.simcardsize));
    ArrayField(
      "operatingsystem",
      getArrayValue(resolutionDetails.operatingsystem)
    );
    ArrayField(
      "phonehumaninterface",
      getArrayValue(resolutionDetails.phonehumaninterface)
    );
    ArrayField(
      "photosensorresolution",
      getArrayValue(resolutionDetails.photosensorresolution)
    );
    ArrayField("videocapture", getArrayValue(resolutionDetails.videocapture));
    ArrayField("itemweight", getArrayValue(resolutionDetails.itemweight));
    ArrayField(
      "includedcomponents",
      getArrayValue(resolutionDetails.includedcomponents)
    );
    ArrayField("connectortype", getArrayValue(resolutionDetails.connectortype));
    ArrayField(
      "connectivitytechnology",
      getArrayValue(resolutionDetails.connectivitytechnology)
    );
    ArrayField("screensize", getArrayValue(resolutionDetails.screensize));
    ArrayField("displaytype", getArrayValue(resolutionDetails.displaytype));
    ArrayField(
      "devicefeatures",
      getArrayValue(resolutionDetails.devicefeatures)
    );
    ArrayField(
      "simcardslotcount",
      getArrayValue(resolutionDetails.simcardslotcount)
    );
    ArrayField("shortby", getArrayValue(resolutionDetails.shortby));
    ArrayField(
      "camera_resolution",
      getArrayValue(resolutionDetails.camera_resolution)
    );

    // Handle image field
    if (croppedImage) {
      formData.append("product_image", croppedImage);
    } else {
      formData.append("current_image", resolutionDetails.product_image);
    }
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/productupdate.php`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();

      if (response.ok) {
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.id === resolutionDetails.id ? resolutionDetails : category
          )
        );
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Product updated successfully!",
        });
        fetch(
          `https://arasurdigital.com/admin/productnametostockname.php?product_id=${resolutionDetails.id}&&product_name=${resolutionDetails.product_name}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            // body: JSON.stringify({
            //   product_name: resolutionDetails.product_name,
            //   id: resolutionDetails.id
            // })
          }
        )
          .then((response) => response.json())
          .then((data) => console.log(data))
          .catch((error) => console.error("Error:", error));

        Navigate("/manageproduct");
        fetchCatData(); // Assuming fetchCatData is defined elsewhere
      } else {
        console.error("Failed to update product:", result.message);
      }
    } catch (error) {
      console.error("An error occurred while sending data:", error.message);
    }
  };

  const handleVariantInsert = async () => {
    try {
      if (
        !varprice ||
        !varientImages.length ||
        !varientImages ||
        !colour ||
        !variantSize ||
        !variantStock ||
        !storage
      ) {
        swal("Warning", "Please enter required fields", "warning");
        return;
      }

      const totalPreviousVariantStock = tableData.reduce((acc, variant) => {
        return acc + parseInt(variant.variantStock || 0, 10); // Parse and sum variant stocks
      }, 0);

      const newTotalStock =
        totalPreviousVariantStock + parseInt(variantStock, 10);
      console.log(" newTotalStock", newTotalStock);
      if (Number(newTotalStock) > Number(productStock)) {
        swal(
          `The total stock for this variant is already ${totalPreviousVariantStock}, while the total product stock is ${productStock}. You  have ${
            Number(productStock) - Number(totalPreviousVariantStock) > 0
              ? Number(productStock) - Number(totalPreviousVariantStock)
              : "no"
          } stock left to add!`
        );
        return; // Prevent form submission
      }

      const newRow = {
        variantStock,
        varprice,
        storage,
        images: [],
      };
      console.log("variants", variants);
      // Loop through variants to get other selected values
      variants.forEach((variant) => {
        const selectedOption = variantOptions[variant]?.find(
          (option) => option.id === selectedVariant[variant]
        );
        newRow[variant] = selectedOption ? selectedOption[variant] : "";
      });
      // Loop through uploaded images and push their URLs into the images array
      Array.from(varientImages).forEach((imageFile) => {
        const imageUrl = URL.createObjectURL(imageFile);
        newRow.images.push(imageUrl);
      });
      // Update table data state
      // setTableData((prevData) => [...prevData, newRow]);
      const formData = new FormData();
      formData.append("product_id", productId);
      formData.append("category_id", selectedCategory);
      formData.append("variant_type", variantType || "defaultType");
      formData.append("variant_size", variantSize || "3");

      // Append the image files to the FormData object
      Array.from(varientImages).forEach((imageFile, index) => {
        formData.append(`variant_image_${index}`, imageFile);
      });
      formData.append("variant_stock", variantStock);
      formData.append("variant_status", variantStatus || "1");
      formData.append("offer_status", offerStatus || "No Offer");
      formData.append("discount_price", discountedPrice || 0);
      formData.append("colour", colour);

      formData.append("storage", storage || "24GB");
      formData.append("price", varprice);
      formData.append("instock_text", instockText || "In Stock");
      formData.append("variant_star", variantStar || 0);
      formData.append("review_count", reviewCount || 0);

      const response = await fetch(
        "https://arasurdigital.com/admin/variant.php",
        {
          method: "POST",
          body: formData,
        }
      );
      const responseData = await response.text(); // Get response as text
      // console.log("Response data:", responseData); // Log response data for debugging

      const data = JSON.parse(responseData);
      if (!response.ok) {
        throw new Error(
          `Failed to insert variant: ${response.status} - ${response.statusText}`
        );
      }

      // const data = await response.json();

      if (data.status === "success") {
        // Variant added successfully
        const newVarientId = data.last_id;
        // console.log({ newVarientId: newVarientId });
        setVarientId(newVarientId);
        setTableData((prevData) => [
          ...prevData,
          {
            ...newRow, // Add any existing data for the new row
            varientId: newVarientId, // Include the variant ID in the new row
          },
        ]);

        setVariantStock("");
        setVarPrice("");
        setStorage("");
        setVarientImages([]);
        setSelectedVariant({});
        const fileInput = fileInputRef.current;
        if (fileInput) {
          fileInput.value = "";
        }

        // Call stockInsert with the newVarientId
        await stockInsert(newVarientId);
      } else {
        // Failed to add variant
        console.error("Failed to add variant:", data.message);
        // Handle the error, show message to the user, etc.
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  const handleInsert = async () => {
    try {
      await handleVariantInsert();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const stockInsert = async (newVarientId) => {
    const formData = new FormData();
    formData.append("product_id", productId);
    formData.append("product_name", productName);
    formData.append("variant_id", newVarientId); // Ensure variant_id is passed correctly
    formData.append("variant_stock", variantStock);
    formData.append("price", varprice);
    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/stock.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to insert stock: ${response.status} - ${response.statusText}`
        );
      }
      const data = await response.json();
      if (data.status === "success") {
      } else {
        console.error("Failed to insert stock:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const stockInsertproduct = async (lastInsertedId) => {
    const formData = new FormData();
    formData.append("product_id", lastInsertedId);
    formData.append("product_name", productName);
    formData.append("variant_id", "");
    formData.append("variant_stock", productStock);
    formData.append("price", price);

    try {
      const response = await fetch(
        "https://arasurdigital.com/admin/stock.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to insert stock: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();

      if (data.status === "success") {
        // console.log("Stock inserted successfully");
      } else {
        console.error("Failed to insert stock:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (type !== null) {
      setResolutionDetails("");
      setCroppedImage("");
    }
  }, [type]);

  const handleAllDetails = () => {
    setProductName("");
    setTags("");
    setPrice("");
    setBrand("");
    setDescription("");
    setMadeIn("");
    setTax("");
    setProductStock("");
    setWarranty("");
    setMainImage(null);
    setIsReturnable(true);
    setIsCancelable(true);
    setIsCODAllowed(true);
    setProductFeatures("");
    setCroppedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
    setCroppedImage("");
    setTags("");
    setUnit("");
    setProductType("");
    setSelectedCountry("");
    setSelectedCategory("");
    setSelectedSubCategory("");
    setBrand(null);
    setProductStock("");
    setWarranty("");
    setProductStatus("");
    setMainImage("");
    setTableData([]);
    // setBrandload([]);
    setTotalAllowedQuantity("");
    setSubCategories("");
    setProduct(false);
    // subCategories([]);
    setProductInfo({
      name: "",
      value: "",
    });
    Navigate("/manageproduct");
  };
  const getstoragecapacity = (categoryValue) => {
    // console.log("Input categoryValue:", categoryValue);

    // If categoryValue is falsy, return an empty array
    if (!categoryValue) return [];

    // Convert categoryValue to an array of IDs if it's a string
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;

    // Check if options array is defined and not empty
    if (!options || options.length === 0) {
      console.warn("Options array is undefined or empty");
      return [];
    }

    // Filter options based on categoryIds, ensure item.id is defined before calling toString
    const filteredOptions = options.filter(
      (item) => item && item.id && categoryIds.includes(item.id.toString()) // Safeguard item and item.id
    );

    // Map filtered options to the format expected by the Select component
    return filteredOptions.map((item) => ({
      value: item.id,
      label: item.storagecapacity,
    }));
  };

  // this get method is multiple value show
  const getProcessorspeed = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = processorspeed.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.processor_speed,
    }));
    return formattedOptions;
  };
  const getbattery_capacity = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = batterycapacity.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.battery_capacity,
    }));
    return formattedOptions;
  };

  const getGps = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = gps.filter((item) => categoryIds.includes(item.id));
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.gps,
    }));
    return formattedOptions;
  };
  const getCameraresolution = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = cameraresolution.filter((item) =>
      categoryIds.includes(item.id)
    );
    // console.log({filteredOptions:filteredOptions})
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.camera_resolution,
    }));
    return formattedOptions;
  };
  const getPhone_Resolution = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = phoneresolution.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.phone_resolution,
    }));
    return formattedOptions;
  };
  const getRefresh_Rate = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = refreshrate.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.refresh_rate,
    }));
    return formattedOptions;
  };
  const getRam_Size = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = ramsize.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.ram_size,
    }));
    return formattedOptions;
  };
  const getSensor_Resolution = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = sensorresolution.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.sensor_resolution,
    }));
    return formattedOptions;
  };
  const getShooting_Modes = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = shooingmode.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.shooting_modes,
    }));
    return formattedOptions;
  };
  const getCellular_Technology = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = cellulartechnology.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.Cellular_technology,
    }));
    return formattedOptions;
  };
  const getSim_Card = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = simcardsize.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.sim_card,
    }));
    return formattedOptions;
  };
  const getOperating_System = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = os.filter((item) => categoryIds.includes(item.id));
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.os,
    }));
    return formattedOptions;
  };
  const getMobile_Phone_Human_Interface = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = interfaceinput.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.interface_input,
    }));
    return formattedOptions;
  };
  const getCellular_Phone_Photo_Sensor = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = cellularphone.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.cellularphone,
    }));
    return formattedOptions;
  };
  const getVideo_Capture = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = videocapture.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.video_capture,
    }));
    return formattedOptions;
  };
  const getItem_Weight = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = itemweight.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.item_weight,
    }));
    return formattedOptions;
  };
  const getIncluded_Components = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = includedcomponents.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.included_components,
    }));
    return formattedOptions;
  };
  const getMobile_Phone_Biometric = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = biometricsecurityfeature.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.biometric_securityfeature,
    }));
    return formattedOptions;
  };
  const getCell_Phone = (categoryValue) => {
    if (!categoryValue) return [];

    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;

    // Convert both IDs to strings for consistent comparison
    const filteredOptions = cellularphone.filter((item) =>
      categoryIds.includes(item.id.toString())
    );
    // console.log({ filteredOptions: categoryIds });

    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id.toString(), // ensure value is a string
      label: item.cellularphone,
    }));
    // console.log({ categoryValue, categoryIds, cellularphone });

    return formattedOptions;
  };

  const getCell_Phone_Type = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = connectortype.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.connector_type,
    }));
    return formattedOptions;
  };
  const getMobile_Phone_Connectivity = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = connectivitytechnology.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.phone_connectivity,
    }));
    return formattedOptions;
  };
  const getScreen_Size = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = screensize.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.screen_size,
    }));
    return formattedOptions;
  };
  const getDisplay_Type = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = displaytype.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.display_type,
    }));
    return formattedOptions;
  };
  const getDevice_Features = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = devicefeatures.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.device_features,
    }));
    return formattedOptions;
  };
  const getSim_Card_Count = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = simcardslotcount.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.simcard_slotcount,
    }));
    return formattedOptions;
  };
  const getShort_By = (categoryValue) => {
    if (!categoryValue) return [];
    const categoryIds =
      typeof categoryValue === "string"
        ? categoryValue.split(",").map((id) => id.trim())
        : categoryValue;
    const filteredOptions = shortby.filter((item) =>
      categoryIds.includes(item.id)
    );
    const formattedOptions = filteredOptions.map((item) => ({
      value: item.id,
      label: item.short_by,
    }));
    return formattedOptions;
  };
  const handleDelete = async (categoryId) => {
    try {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this variant!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      console.log({ categoryId: categoryId });
      if (shouldDelete) {
        const response = await fetch(
          `https://arasurdigital.com/admin/variant.php?id=${categoryId}`,
          {
            method: "DELETE",
          }
        );
        if (response.ok) {
          setTableData((prevData) =>
            prevData.filter((row) => row.varientId !== categoryId)
          );
          swal("Deleted!", "variant has been deleted.", "success");
        } else {
          console.error("Failed to delete category");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="main-container mt-3">
      {loading ? (
        <div>
          <Lottie
            height={250}
            width={250}
            options={{
              loop: true,
              animationData: require("../Assets/Animation - 1716197548567.json"),
              autoplay: true,
            }}
          />
        </div>
      ) : (
        <div className=" ">
          <div className="flex-container pt-5">
            {type !== "" ? (
              <h3 className="head-fon-mv">Edit Product</h3>
            ) : (
              <h3 className="head-fon-mv">Create Product</h3>
            )}

            <div class="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-header float-start float-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/dashboard")}>Dashboard</a>
                  </li>{" "}
                  <li class="breadcrumb-item">
                    <a onClick={() => Navigate("/manageproduct")}>
                      Manage Products
                    </a>
                  </li>{" "}
                  {type !== "" ? (
                    <li aria-current="page" class="breadcrumb-item active">
                      Edit Product
                    </li>
                  ) : (
                    <li aria-current="page" class="breadcrumb-item active">
                      Create Product
                    </li>
                  )}
                </ol>
              </nav>
            </div>
          </div>

          <div className="card ">
            <div className="cat-left">
              {/* {type !== "" ? <h4>Edit Product</h4> : <h4>Create Product</h4>} */}
            </div>
            <div className="form-container">
              <div className="form-column">
                <label className="bold">
                  Product Name <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Product Name"
                  value={productName || resolutionDetails?.product_name || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^[a-zA-Z0-9\s'-]*$/.test(value)) {
                      setProductName(value);
                      if (id) {
                        setResolutionDetails((prevDetails) => ({
                          ...prevDetails,
                          product_name: value,
                        }));
                      }
                    }
                  }}
                />

                <label className="bold">Tax</label>
                <select
                  className="form-select"
                  value={tax || resolutionDetails?.tax || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setTax(value);
                    if (id) {
                      setResolutionDetails((prevDetails) => ({
                        ...prevDetails,
                        tax: value,
                      }));
                    }
                  }}
                >
                  <option value="">Select Tax</option>
                  {taxOptions.map((option) => (
                    <option key={option.id} value={option.percentage}>
                      {option.percentage}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-column">
                <label className="bold">
                  Product Type <i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Product Type"
                  value={productType || resolutionDetails?.product_type || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    setProductType(value);
                    if (id) {
                      setResolutionDetails((prevDetails) => ({
                        ...prevDetails,
                        product_type: value,
                      }));
                    }
                  }}
                />
                {/* <select className="form-select" /> */}
                <label className="bold">
                  Made In <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={selectedCountry || resolutionDetails?.made_in || ""}
                  onChange={handleCountryChange}
                >
                  <option value="">Select Country</option>
                  {madeinload &&
                    madeinload.map(
                      (madein) =>
                        madein &&
                        madein.name &&
                        madein.name.common && (
                          <option key={madein.id} value={madein.name.common}>
                            {madein.name.common}
                          </option>
                        )
                    )}
                </select>
              </div>
            </div>
            <div className="form-container">
              <div className="form-column">
                <label className="bold">
                  Tags<i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Tags"
                  value={tags || resolutionDetails?.tags || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (id) {
                      setResolutionDetails((prevDetails) => ({
                        ...prevDetails,
                        tags: value,
                      }));
                    }

                    setTags(value);
                  }}
                />
                <label className="bold">
                  MRP Price<i className="text-danger">*</i>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter MRP Price"
                  value={mrpprice || resolutionDetails?.tags || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (id) {
                      setResolutionDetails((prevDetails) => ({
                        ...prevDetails,
                        tags: value,
                      }));
                    }

                    setMrpPrice(value);
                  }}
                />
                <label className="bold">
                  Brand<i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={brand || resolutionDetails?.brand || ""}
                  onChange={(e) => {
                    const { value } = e.target;

                    // If "Select Brand Name" is chosen or empty, save as 0
                    const newValue = value === "" ? 0 : value;

                    setBrand(newValue); // Update brand value

                    if (id) {
                      setResolutionDetails((prevDetails) => ({
                        ...prevDetails,
                        brand: newValue, // Save 0 if empty
                      }));
                    }
                  }}
                >
                  <option value="">Select Brand Name</option>
                  {brandload &&
                    brandload.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.brand_name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-column">
                <label className="bold">
                  Discount<i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  placeholder="Enter Discount"
                  value={discount || resolutionDetails?.tags || ""}
                  onChange={(e) => {
                    const { value } = e.target;
                    

                    setDiscount(value);
                  }}
                >
                  <option value="" disabled>
                    Select Discount Type
                  </option>
                  <option value="0">Amount</option>
                  <option value="1">Percentage</option>
                </select>

                <label className="bold">
                  Price <i className="text-danger">*</i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Price "
                  value={price || resolutionDetails?.pro_price || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validate that the value contains only numbers and commas
                    if (/^[\d,]*$/.test(value)) {
                      setPrice(value);
                    }
                    if (id) {
                      setResolutionDetails((prevDetails) => ({
                        ...prevDetails,
                        pro_price: value,
                      }));
                    }
                  }}
                />

                <label className="bold">
                  Category <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={selectedCategory || resolutionDetails?.category || ""}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  <option value="">Select Category Name</option>
                  {categories &&
                    categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.category_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="form-new" style={{ display: "flex", gap: "10px" }}>
              <div style={{ flex: 1 }}>
                <label className="bold">
                  Sub Category <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={
                    selectedSubCategoryId ||
                    (resolutionDetails && resolutionDetails.category_id) ||
                    ""
                  }
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    handleSubCategoryChange(selectedValue);
                    // setSelectedSubCategoryId(selectedValue);
                  }}
                >
                  <option value="">Select Sub Category</option>
                  {subCategories &&
                    subCategories.map((subCategory) => (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.category_name}
                      </option>
                    ))}
                </select>
              </div>

              <div style={{ flex: 1 }}>
                <label className="bold">
                  Unit <i className="text-danger">*</i>
                </label>
                <select
                  className="form-select"
                  value={unit || resolutionDetails?.unit || ""}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setUnit(selectedValue); // Update the state with the selected value

                    if (id) {
                      setResolutionDetails((prevDetails) => ({
                        ...prevDetails,
                        unit: selectedValue,
                      }));
                    }
                  }}
                >
                  <option value="">Select Unit</option>
                  {unitload &&
                    unitload.map((unitItem) => (
                      <option key={unitItem.id} value={unitItem.unit_name}>
                        {unitItem.unit_name}
                      </option>
                    ))}
                </select>
              </div>

              <div style={{ flex: 1 }} className="m-2">
                {id ? (
                  <>
                    <label className="bold">
                      Opening Stock <i className="text-danger">*</i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Product Stock"
                      value={resolutionDetails?.product_stock || ""}
                      readOnly
                    />
                  </>
                ) : (
                  <>
                    <label className="bold">
                      Opening Stock <i className="text-danger">*</i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Product Stock"
                      value={productStock}
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        const filteredValue = inputVal.replace(/\D/g, ""); // Only digits
                        setProductStock(filteredValue);
                      }}
                      readOnly={isSaved} // Input becomes read-only after save
                    />
                  </>
                )}
              </div>
              <div style={{ flex: 1 }} className="m-2">
                {id ? (
                  <>
                    <label className="bold">
                      Warranty <i className="text-danger">*</i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Warranty"
                      value={resolutionDetails?.warranty || ""}
                    />
                  </>
                ) : (
                  <>
                    <label className="bold">
                      Warranty <i className="text-danger">*</i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Warranty"
                      value={warranty}
                      onChange={(e) => {
                        const inputVal = e.target.value;
                        // Allow only alphabets and numbers (no special characters)
                        const filteredValue = inputVal.replace(
                          /[^a-zA-Z0-9 ]/g,
                          ""
                        );
                        setWarranty(filteredValue);
                      }}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="form-container">
              <div className="form-column">
                <div className="input-group d-flex justify-content-between">
                  <label className="bold">
                    Is Returnable?
                    <div
                      role="radiogroup"
                      className="btn-group-toggle btn-group bv-no-focus-ring align-items-center"
                    >
                      <label
                        className={`btn btn-outline-primary ${
                          (id && resolutionDetails?.returnable === "Yes") ||
                          (!id && isReturnable)
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={
                            id
                              ? resolutionDetails?.returnable === "Yes"
                              : isReturnable
                          }
                          onChange={(e) => {
                            handleRadioChangeRe(
                              e,
                              setIsReturnable,
                              setResolutionDetails
                            );

                            if (id) {
                              setResolutionDetails((prevDetails) => ({
                                ...prevDetails,
                                returnable: e.target.value,
                              }));
                            }
                          }}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${
                          (id && resolutionDetails?.returnable === "No") ||
                          (!id && !isReturnable)
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={
                            id
                              ? resolutionDetails?.returnable === "No"
                              : !isReturnable
                          }
                          onChange={(e) => {
                            handleRadioChangeRe(
                              e,
                              setIsReturnable,
                              setResolutionDetails
                            );

                            if (id) {
                              setResolutionDetails((prevDetails) => ({
                                ...prevDetails,
                                returnable: e.target.value,
                              }));
                            }
                          }}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  <label className="bold">
                    Is Cancelable?
                    <div
                      role="radiogroup"
                      className="btn-group-toggle btn-group bv-no-focus-ring"
                    >
                      <label
                        className={`btn btn-outline-primary ${
                          (id && resolutionDetails?.cancelable === "Yes") ||
                          (!id && isCancelable)
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={
                            id
                              ? resolutionDetails?.cancelable === "Yes"
                              : isCancelable
                          }
                          onChange={(e) => {
                            handleRadioChangeCan(
                              e,
                              setIsCancelable,
                              setResolutionDetails
                            );

                            if (id) {
                              setResolutionDetails((prevDetails) => ({
                                ...prevDetails,
                                cancelable: e.target.value,
                              }));
                            }
                          }}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${
                          (id && resolutionDetails?.cancelable === "No") ||
                          (!id && !isCancelable)
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={
                            id
                              ? resolutionDetails?.cancelable === "No"
                              : !isCancelable
                          }
                          onChange={(e) => {
                            handleRadioChangeCan(
                              e,
                              setIsCancelable,
                              setResolutionDetails
                            );

                            if (id) {
                              setResolutionDetails((prevDetails) => ({
                                ...prevDetails,
                                cancelable: e.target.value,
                              }));
                            }
                          }}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  <label className="bold">
                    Is COD Allowed?
                    <div
                      role="radiogroup"
                      className="btn-group-toggle btn-group bv-no-focus-ring"
                    >
                      <label
                        className={`btn btn-outline-primary ${
                          (id && resolutionDetails?.cod === "Yes") ||
                          (!id && isCODAllowed)
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value="Yes"
                          checked={
                            id ? resolutionDetails?.cod === "Yes" : isCODAllowed
                          }
                          onChange={(e) => {
                            handleRadioChangeCod(e, setIsCODAllowed);

                            if (id) {
                              setResolutionDetails((prevDetails) => ({
                                ...prevDetails,
                                cod: e.target.value,
                              }));
                            }
                          }}
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        className={`btn btn-outline-primary ${
                          (id && resolutionDetails?.cod === "No") ||
                          (!id && !isCODAllowed)
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value="No"
                          checked={
                            id ? resolutionDetails?.cod === "No" : !isCODAllowed
                          }
                          onChange={(e) => {
                            handleRadioChangeCod(e, setIsCODAllowed);

                            if (id) {
                              setResolutionDetails((prevDetails) => ({
                                ...prevDetails,
                                cod: e.target.value,
                              }));
                            }
                          }}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </label>

                  <label className="bold">
                    Product Status
                    <div
                      role="radiogroup"
                      className="btn-group-toggle btn-group bv-no-focus-ring"
                    >
                      {/* Approved Radio Button */}
                      <label
                        className={`btn btn-outline-primary ${
                          (id &&
                            resolutionDetails?.product_status === "Approved") ||
                          (!id && productStatus === "Approved")
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value="Approved"
                          checked={
                            id
                              ? resolutionDetails?.product_status === "Approved"
                              : productStatus === "Approved"
                          }
                          onChange={() => handleRadioChange("Approved")}
                        />
                        <span>Approved</span>
                      </label>

                      {/* Not Approved Radio Button */}
                      <label
                        className={`btn btn-outline-primary ${
                          (id &&
                            resolutionDetails?.product_status ===
                              "Not-Approved") ||
                          (!id && productStatus === "Not-Approved")
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          value="Not-Approved"
                          checked={
                            id
                              ? resolutionDetails?.product_status ===
                                "Not-Approved"
                              : productStatus === "Not-Approved"
                          }
                          onChange={() => handleRadioChange("Not-Approved")}
                        />
                        <span>Not Approved</span>
                      </label>
                    </div>
                  </label>
                </div>

                <div className="input-group"></div>
              </div>
            </div>
            {(selectedSubCategoryId && selectedCategory) || id !== null ? (
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Extendable Storage</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Extendable Storage"
                        onChange={handleInputInfoChangecell}
                        isMulti
                        value={
                          id
                            ? getstoragecapacity(
                                resolutionDetails?.storage_capacity
                              )
                            : selectedcellstorage
                        }
                        options={options.map((option) => ({
                          value: option.id,
                          label: option.storagecapacity,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Processor Speed</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Cellular Memory Storage Capacity"
                        onChange={handleInputInfoChangeprocessorspeed}
                        isMulti
                        value={
                          id
                            ? getProcessorspeed(
                                resolutionDetails?.processor_speed
                              )
                            : selectedprocessorspeed
                        }
                        options={processorspeed.map((option) => ({
                          value: option.id,
                          label: option.processor_speed,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Battery Capacity</label>
                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Cellular Memory Storage Capacity"
                        onChange={handleInputInfoChangebatterycapacity}
                        isMulti
                        value={
                          id
                            ? getbattery_capacity(
                                resolutionDetails?.battery_capacity
                              )
                            : selectedbatterycapacity
                        }
                        options={batterycapacity.map((option) => ({
                          value: option.id,
                          label: option.battery_capacity,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Gps</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Gps"
                        onChange={handleInputInfoChangegps}
                        isMulti
                        value={
                          id ? getGps(resolutionDetails?.gps) : selectedgps
                        }
                        options={gps.map((option) => ({
                          value: option.id,
                          label: option.gps,
                        }))}
                      />
                    </div>

                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Display Resolution</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Display  Resolution"
                        isMulti
                        onChange={handleInputInfoChangephoneresolution}
                        value={
                          id
                            ? getPhone_Resolution(
                                resolutionDetails?.phoneresolution
                              )
                            : selectedphoneresolution
                        }
                        options={phoneresolution.map((option) => ({
                          value: option.id,
                          label: option.phone_resolution,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Refresh Rate</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Refresh Rate"
                        isMulti
                        onChange={handleInputInfoChangerefreshrate}
                        value={
                          id
                            ? getRefresh_Rate(resolutionDetails?.refreshrate)
                            : selectedrefreshrate
                        }
                        options={refreshrate.map((option) => ({
                          value: option.id,
                          label: option.refresh_rate,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Ram/Rom Size</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Ram/Rom Size"
                        isMulti
                        onChange={handleInputInfoChangeramsize}
                        value={
                          id
                            ? getRam_Size(resolutionDetails?.ramsize)
                            : selectedramsize
                        }
                        options={ramsize.map((option) => ({
                          value: option.id,
                          label: option.ram_size,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Quick charging</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Quick charging"
                        isMulti
                        onChange={handleInputInfoChangesensorresolution}
                        value={
                          id
                            ? getSensor_Resolution(
                                resolutionDetails?.sensorresolution
                              )
                            : selectedsensorresolution
                        }
                        options={sensorresolution.map((option) => ({
                          value: option.id,
                          label: option.sensor_resolution,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Audio Jack</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Audio Jack"
                        isMulti
                        onChange={handleInputInfoChangeshootingmodes}
                        value={
                          id
                            ? getShooting_Modes(
                                resolutionDetails?.shootingmodes
                              )
                            : selectedshootingmodes
                        }
                        options={shooingmode.map((option) => ({
                          value: option.id,
                          label: option.shooting_modes,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Cellular Technology</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Cellular Technology"
                        isMulti
                        onChange={handleInputInfoChangecellulartechnology}
                        value={
                          id
                            ? getCellular_Technology(
                                resolutionDetails?.cellulartechnology
                              )
                            : selectedcellulartechnology
                        }
                        options={cellulartechnology.map((option) => ({
                          value: option.id,
                          label: option.Cellular_technology,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Sim Card Size</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Sim Card Size"
                        isMulti
                        onChange={handleInputInfoChangesimcardsize}
                        value={
                          id
                            ? getSim_Card(resolutionDetails?.simcardsize)
                            : selectedsimcardsize
                        }
                        options={simcardsize.map((option) => ({
                          value: option.id,
                          label: option.sim_card,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Operating System</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Operating System"
                        isMulti
                        onChange={handleInputInfoChangeoperatingsystem}
                        value={
                          id
                            ? getOperating_System(
                                resolutionDetails?.operatingsystem
                              )
                            : selectedoperatingsystem
                        }
                        options={os.map((option) => ({
                          value: option.id,
                          label: option.os,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Device interface</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Device interface"
                        isMulti
                        onChange={handleInputInfoChangephonehumaninterface}
                        value={
                          id
                            ? getMobile_Phone_Human_Interface(
                                resolutionDetails?.phonehumaninterface
                              )
                            : selectedphonehumaninterface
                        }
                        options={interfaceinput.map((option) => ({
                          value: option.id,
                          label: option.interface_input,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Front Camera </label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Front Camera"
                        isMulti
                        onChange={handleInputInfoChangecameraresolution}
                        value={
                          id
                            ? getCameraresolution(
                                resolutionDetails?.camera_resolution
                              )
                            : selectedcameraresolution
                        }
                        options={cameraresolution.map((option) => ({
                          value: option.id,
                          label: option.camera_resolution,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Rear Camera</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Rear Camera"
                        isMulti
                        onChange={handleInputInfoChangephotosensorresolution}
                        value={
                          id
                            ? getCell_Phone(
                                resolutionDetails?.photosensorresolution
                              )
                            : selectedphotosensorresolution
                        }
                        options={cellularphone.map((option) => ({
                          value: option.id,
                          label: option.cellularphone,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Video Capture Quality</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Video Capture Quality"
                        isMulti
                        onChange={handleInputInfoChangevideocapture}
                        value={
                          id
                            ? getVideo_Capture(resolutionDetails?.videocapture)
                            : selectedvideocapture
                        }
                        options={videocapture.map((option) => ({
                          value: option.id,
                          label: option.video_capture,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Item Weight</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Item Weight"
                        isMulti
                        onChange={handleInputInfoChangeitemweight}
                        value={
                          id
                            ? getItem_Weight(resolutionDetails?.itemweight)
                            : selecteditemweight
                        }
                        options={itemweight.map((option) => ({
                          value: option.id,
                          label: option.item_weight,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold"> In the box</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select  In the box"
                        isMulti
                        onChange={handleInputInfoChangeincludedcomponents}
                        value={
                          id
                            ? getIncluded_Components(
                                resolutionDetails?.includedcomponents
                              )
                            : selectedincludedcomponents
                        }
                        options={includedcomponents.map((option) => ({
                          value: option.id,
                          label: option.included_components,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Mobile Security Lock</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Mobile Security Lock"
                        isMulti
                        onChange={handleInputInfoChangebiometricsecurity}
                        value={
                          id
                            ? getMobile_Phone_Biometric(
                                resolutionDetails?.biometricsecurity
                              )
                            : selectedbiometricsecurity
                        }
                        options={biometricsecurityfeature.map((option) => ({
                          value: option.id,
                          label: option.biometric_securityfeature,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold"> Phone Connector Type</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select  Phone Connector Type"
                        isMulti
                        onChange={handleInputInfoChangeconnectortype}
                        value={
                          id
                            ? getCell_Phone_Type(
                                resolutionDetails?.connectortype
                              )
                            : selectedconnectortype
                        }
                        options={connectortype.map((option) => ({
                          value: option.id,
                          label: option.connector_type,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Connectivity Technology</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select  Connectivity Technology"
                        isMulti
                        onChange={handleInputInfoChangeconnectivitytechnologye}
                        value={
                          id
                            ? getMobile_Phone_Connectivity(
                                resolutionDetails?.connectivitytechnology
                              )
                            : selectedconnectivitytechnology
                        }
                        options={connectivitytechnology.map((option) => ({
                          value: option.id,
                          label: option.phone_connectivity,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Screen Size</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Screen Size"
                        isMulti
                        onChange={handleInputInfoChangescreensize}
                        value={
                          id
                            ? getScreen_Size(resolutionDetails?.screensize)
                            : selectedscreensize
                        }
                        options={screensize.map((option) => ({
                          value: option.id,
                          label: option.screen_size,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Display Type</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Display Type"
                        isMulti
                        onChange={handleInputInfoChangedisplaytypee}
                        value={
                          id
                            ? getDisplay_Type(resolutionDetails?.displaytype)
                            : selecteddisplaytype
                        }
                        options={displaytype.map((option) => ({
                          value: option.id,
                          label: option.display_type,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Device Features</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Device Features"
                        isMulti
                        onChange={handleInputInfoChangedevicefeatures}
                        value={
                          id
                            ? getDevice_Features(
                                resolutionDetails?.devicefeatures
                              )
                            : selecteddevicefeatures
                        }
                        options={devicefeatures.map((option) => ({
                          value: option.id,
                          label: option.device_features,
                        }))}
                      />
                    </div>

                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Sim Card Slot Count</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Sim Card Slot Count"
                        isMulti
                        onChange={handleInputInfoChangesimcardslotcount}
                        value={
                          id
                            ? getSim_Card_Count(
                                resolutionDetails?.simcardslotcount
                              )
                            : selectedsimcardslotcount
                        }
                        options={simcardslotcount.map((option) => ({
                          value: option.id,
                          label: option.simcard_slotcount,
                        }))}
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <label className="bold">Short By</label>

                      <Select
                        id="categorySelect"
                        className="form-control"
                        placeholder="Select Short By"
                        isMulti
                        onChange={handleInputInfoChangeshortby}
                        value={
                          id
                            ? getShort_By(resolutionDetails?.shortby)
                            : selectedshortby
                        }
                        options={shortby.map((option) => ({
                          value: option.id,
                          label: option.short_by,
                        }))}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div>
              <label className="bold">
                Description <i className="text-danger">*</i>
              </label>
              <ReactQuill
                theme="snow"
                value={description || resolutionDetails?.description || ""}
                onChange={handleDescriptionChange}
              />
            </div>
            <div>
              <label className="bold">
                Product Features<i className="text-danger">*</i>
              </label>
              <ReactQuill
                theme="snow"
                value={
                  productFeatures || resolutionDetails?.product_features || ""
                }
                onChange={handleProductFeaturesChange}
              />
            </div>
            <div>
              <label className="bold">
                Main Image <i className="text-danger">*</i>
              </label>
              <p>Please choose a square image less 140KB.</p>
              <label className="mb-4">
                {!selectedImages && id && (
                  <img
                    src={`https://arasurdigital.com/admin/image/product/${resolutionDetails?.product_image}`}
                    width={80}
                    height={80}
                    style={{ margin: "10px" }}
                  />
                )}
                {/* <div className="selected-images">
                  {selectedImages ? (
                    <img
                      src={URL.createObjectURL(selectedImages)}
                      alt={`Selected `}
                      className="selected-image"
                    />
                  ) : (
                    ""
                  )}
                </div> */}
                {croppedImage &&
                  croppedImage.map((file, index) => {
                    const imageUrl = URL.createObjectURL(file);
                    return (
                      <img
                        key={index}
                        src={imageUrl}
                        alt={`Selected ${index + 1}`}
                        className="selected-image"
                      />
                    );
                  })}

                <input
                  multiple
                  type="file"
                  className="form-control"
                  onChange={(e) => handleFileChange(e)}
                />

                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              </label>
            </div>

            <div className="save-bt ">
              <button
                className="btn btn-danger "
                style={{ marginRight: "10px" }}
                onClick={() => navigate("/manageproduct")}
              >
                Cancel
              </button>

              <button
                onClick={id ? handleupdate : handleInsertProduct}
                disabled={isLoading}
                className="btn btn-success "
                style={{ width: "90px", float: "left" }}
              >
                {isLoading
                  ? id
                    ? "Updating..."
                    : "Saving..."
                  : id
                  ? "Update"
                  : "Save"}
              </button>
            </div>
          </div>
          <div>
            {selectedCategory && product && (
              <div>
                <div className="form-container2">
                  <form onSubmit={handleSubmit}>
                    <div>
                      {/* Existing select inputs for variants */}
                      {variants.map((variant, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <label>
                            {variant} <i className="text-danger">*</i>
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) =>
                              handleVariantChange(variant, e.target.value)
                            }
                            value={selectedVariant[variant] || ""}
                          >
                            <option value="">Select {variant}</option>
                            {variantOptions[variant]?.map(
                              (option, optionIndex) => (
                                <option key={optionIndex} value={option.id}>
                                  {option.hasOwnProperty(variant)
                                    ? option[variant]
                                    : ""}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      ))}

                      <label className="form-label">
                        Variant Stock: <i className="text-danger ">*</i>
                        <input
                          className="form-control"
                          placeholder="Variant Stock"
                          value={variantStock}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[\d,]*$/.test(value)) {
                              setVariantStock(e.target.value);
                            }
                          }}
                        />
                      </label>

                      <label className="form-label m-3">
                        Price: <i className="text-danger ">*</i>
                        <input
                          className="form-control"
                          placeholder="Price"
                          value={varprice}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[\d,]*$/.test(value)) {
                              setVarPrice(value);
                            }
                          }}
                        />
                      </label>
                      <label className="form-label m-3">
                        RAM: <i className="text-danger ">*</i>
                        <input
                          className="form-control"
                          placeholder="RAM"
                          value={storage}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[a-zA-Z\d,]*$/.test(value)) {
                              setStorage(value);
                            }
                          }}
                        />
                      </label>

                      <label>
                        Images: <i className="text-danger">*</i>
                        <input
                          ref={fileInputRef}
                          multiple
                          type="file"
                          className="form-control"
                          onChange={(e) => handleImageUpload(e)}
                        />
                      </label>

                      <button
                        onClick={handleInsert}
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          margin: "3px",
                          border: "none",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>

                <div>
                  <h2>Table Data</h2>
                  <table border="1">
                    <thead>
                      <tr>
                        {variants.map((variant, index) => (
                          <th key={index}>{variant}</th>
                        ))}
                        <th>Variant Stock</th>
                        <th>Price</th>
                        <th>RAM</th>
                        <th>images</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((rowData, index) => (
                        <tr key={index}>
                          {variants.map((variant, index) => (
                            <td key={index}>{rowData[variant]}</td>
                          ))}
                          <td>{rowData.variantStock}</td>
                          <td>{rowData.varprice}</td>
                          <td>{rowData.storage}</td>
                          <td>
                            {rowData.images.map((imageUrl, idx) => (
                              <img
                                key={idx}
                                src={imageUrl}
                                alt={`Image ${idx}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                              />
                            ))}
                          </td>
                          <td>
                            <button
                              className="btn-edit"
                              onClick={() => handleDelete(rowData.varientId)}
                            >
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <button
                  onClick={handleAllDetails}
                  className="btn btn-success m-2"
                >
                  Save all varients
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Addproduct;
