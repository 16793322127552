import "./Manageproduct.css";
import React, { useEffect, useState } from "react";
import { FaSearch, FaTrash, FaPen, FaCalendar } from "react-icons/fa";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function ManageProduct() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [productName, setproductName] = useState([]);

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  console.log({ products: products });
  const [loading, setLoading] = useState(true);
  const [manageproduct, setManageproduct] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [modalContent, setModalContent] = useState("");
  const [imagebox, setImagebox] = useState();
  useEffect(() => {
    // Fetch data from the provided link
    fetch("https://arasurdigital.com/admin/product.php")
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handleOpenModal = (productId, productName) => {
    setproductName(productName);
    fetch(`https://arasurdigital.com/admin/variant.php?product_id=${productId}`)
      .then((response) => response.json())
      .then((data) => {
        setModalContent(data);
        setShowModal(true);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Filter products based on search query
    const filtered = products.filter((product) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        product.product_name.toLowerCase().includes(searchLower) ||
        product.product_code.toLowerCase().includes(searchLower) ||
        product.category.toLowerCase().includes(searchLower) ||
        product.brand.toLowerCase().includes(searchLower) ||
        product.pro_price.toString().includes(searchQuery)
      );
    });
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  const sortedProducts = [...filteredProducts].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [filteredCapacitie, setFilteredCapacities] = useState([]);
  // Data for current page
  const currentPageData = filteredCapacitie.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredCapacitie.length / itemsPerPage);
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    const visiblePages = 3;
    const totalPages = Math.ceil(filteredCapacitie.length / itemsPerPage);

    // Adjust startPage and endPage based on currentPage
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    // Add leading "..." if startPage is greater than 2
    if (startPage > 2) {
      paginationNumbers.push(1);
      paginationNumbers.push("...");
    }

    // Push the range of page numbers
    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    // Add trailing "..." if endPage is less than totalPages - 1
    if (endPage < totalPages) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    const totalPages = Math.ceil(sortedProducts.length / newItemsPerPage);
    const newCurrentPage = Math.min(currentPage, totalPages);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(newCurrentPage);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDelete = async (productId) => {
    try {
      // Check if the product has variants
      const variantResponse = await fetch(
        `https://arasurdigital.com/admin/check_variants.php?id=${productId}`
      );
      const variantData = await variantResponse.json();

      if (variantData.hasVariants) {
        // Warn the user that this product has variants
        const shouldDelete = await swal({
          title: "Are you sure?",
          text: "This product has variants. Deleting this will remove all associated variants and stock data. Once deleted, you will not be able to recover this product!",
          icon: "warning",
          buttons: ["Cancel", "Delete"],
          dangerMode: true,
        });

        if (shouldDelete) {
          const response = await fetch(
            `https://arasurdigital.com/admin/product.php?product_id=${productId}`,
            {
              method: "PUT",
            }
          );

          if (response.ok) {
            setProducts(products.filter((product) => product.id !== productId));
            swal(
              "Deleted!",
              "Product and its variants have been deleted.",
              "success"
            );
          } else {
            console.error("Failed to delete product");
          }
        }
      } else {
        // If no variants, confirm deletion
        const shouldDelete = await swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this product!",
          icon: "warning",
          buttons: ["Cancel", "Delete"],
          dangerMode: true,
        });

        if (shouldDelete) {
          const response = await fetch(
            `https://arasurdigital.com/admin/product.php?product_id=${productId}`,
            {
              method: "PUT",
            }
          );

          if (response.ok) {
            setProducts(products.filter((product) => product.id !== productId));
            swal("Deleted!", "Product has been deleted.", "success");
          } else {
            console.error("Failed to delete product");
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEdit = (product) => {
    navigate("/editproduct", { state: { product } });
    localStorage.setItem("selectedOption", "addproduct");
    localStorage.setItem("activeItem", "products");
  };

  useEffect(() => {
    const roleget = async () => {
      const role = await localStorage.getItem("role");

      await fetchPermissions(role);
    };
    roleget();
  }, []);

  const fetchPermissions = async (role) => {
    try {
      const response = await fetch(
        `https://arasurdigital.com/admin/roleassign.php?id=${role}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setManageproduct(data.permission.manageProductsView);
      // Process the fetched data
    } catch (error) {
      console.error("Error fetching permissions:", error.message);
    }
  };

  const [variant, setVariant] = useState([]);

  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    setVariant(products);
  }, [products]);

  const [sortColumn, setSortColumn] = useState("");
  const handleSortByColumn = (column) => {
    const sortedBrands = [...currentPageData].sort((a, b) => {
      const valueA = a[column] ? a[column].toString() : ""; // Convert to string if not undefined
      const valueB = b[column] ? b[column].toString() : ""; // Convert to string if not undefined

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    setVariant(sortedBrands);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  useEffect(() => {
    const updatedFilteredCapacities = variant.filter((capacity) => {
      // Ensure promo_code is defined and is a string before calling toLowerCase
      const promoCode = capacity.product_name || ""; // Default to an empty string if undefined
      return promoCode.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredCapacities(updatedFilteredCapacities);
  }, [variant, searchQuery]);

  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Manage Products</h3>
        <div className="col-12 col-md-6 order-md-2 order-first">
          <nav
            aria-label="breadcrumb"
            className="breadcrumb-header float-start float-lg-end"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a onClick={() => navigate("/dashboard")}>Dashboard</a>
              </li>
              <li aria-current="page" className="breadcrumb-item active">
                Manage Products
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="card-cat">
        <div className="cat-left"></div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="row">
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                style={{ height: "35px" }}
                className="form-select m-0 "
                value={itemsPerPage}
                onChange={handlePerPageChange}
              >
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
              {/* <label>Entries</label> */}
            </div>
          </div>
          {/* <div className="search-left">
            <div className="searchbar">
              <input
                type="text"
                className="search"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <FaSearch className="search-icon" />
            </div>
          </div> */}
          {/* {manageproduct.add === true ? (
            <button
              className="add-category-button"
              onClick={() => navigate("/product")}
            >
              Add Products
            </button>
          ) : (
            ""
          )} */}

          <div
            className="d-flex align-items-center ms-auto"
            style={{ padding: "10px" }}
          >
            <div className="searchbar me-3">
              <input
                type="text"
                className="search"
                placeholder="Search"
                onChange={handleSearchChange}
              />
              <FaSearch className="search-icon" />
            </div>
            <div style={{ padding: "10px", marginBottom: "6px" }}>
              {manageproduct.add === true ? (
                <button
                  className="add-category-button"
                  onClick={() => navigate("/product")}
                >
                  Add Products
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="table-container text-end">
          {loading ? (
            <div>
              <Lottie
                height={250}
                width={250}
                options={{
                  loop: true,
                  animationData: require("../../Assets/Animation - 1716197548567.json"),
                  autoplay: true,
                }}
              />
            </div>
          ) : (
            <table id="table-to-xls" className="text-center">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.No</th>
                  <th
                    onClick={() => handleSortByColumn("product_name")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Product Name
                    {sortColumn === "product_name" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th style={{ textAlign: "center" }}>Product Image</th>
                  {/* <th style={{ textAlign: "center" }}>Product Code</th> */}
                  {/* <th
                    onClick={() => handleSortByColumn("product_code")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Product Code
                    {sortColumn === "product_code" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th> */}
                  {/*  <th style={{textAlign: "center" }}>Product Price</th> */}
                  <th
                    onClick={() => handleSortByColumn("product_name")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Product Price
                    {sortColumn === "product_name" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  <th
                    onClick={() => handleSortByColumn("mrpprice")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    MRP Price
                    {sortColumn === "mrpprice" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>
                  {/* <th style={{ textAlign: "center" }}>Product Stock</th> */}
                  <th
                    onClick={() => handleSortByColumn("product_stock")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    Closing Stock
                    {sortColumn === "product_stock" &&
                      (sortOrder === "asc" ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "8px" }}
                        />
                      ))}
                  </th>

                  {manageproduct.edit === true ||
                  manageproduct.delete === true ||
                  manageproduct.viewProduct === true ? (
                    <th style={{ textAlign: "center" }}>Action</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {currentPageData.length > 0 ? (
                  currentPageData.map((product, index) => (
                    <tr key={product.id}>
                      <td style={{ textAlign: "center" }}>
                        {startIndex + index + 1}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {product.product_name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {(() => {
                          let imageUrl = null;

                          try {
                            // Try to parse the product_image as JSON (array of images)
                            const images = JSON.parse(product.product_image);
                            imageUrl = images[0]; // Get the first image from the array
                          } catch (error) {
                            console.error(
                              "Invalid JSON format for product_image:",
                              error
                            );
                            // If it's not JSON, use product.product_image as a single image
                            imageUrl =
                              product.product_image || "placeholder.jpg"; // Use placeholder if no image is provided
                          }

                          return (
                            <img
                              src={`https://arasurdigital.com/admin/image/product/${imageUrl}`}
                              width={40}
                              height={40}
                              style={{ margin: "10px" }}
                              alt={product.product_name}
                            />
                          );
                        })()}
                      </td>

                      {/* <td style={{ textAlign: "center" }}>
                        {product.product_code}
                      </td> */}
                      <td style={{ textAlign: "center" }}>
                        {product.pro_price}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {product.mrpprice}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {product.closeing_stock}
                      </td>

                      {(manageproduct.edit ||
                        manageproduct.delete ||
                        manageproduct.viewProduct) && (
                        <td style={{ textAlign: "center" }}>
                          {manageproduct.edit && (
                            <button
                              className="btn-edit"
                              onClick={() => handleEdit(product.id)}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} />
                            </button>
                          )}

                          {manageproduct.viewProduct && (
                            <button
                              className="btn-view"
                              onClick={() =>
                                handleOpenModal(
                                  product.id,
                                  product.product_name
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ cursor: "pointer" }}
                              />
                            </button>
                          )}

                          {manageproduct.delete && (
                            <button
                              className="btn-delete"
                              onClick={() => handleDelete(product.id)}
                            >
                              <FaTrash />
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers p-2"
        id="dtBasicExample_paginate"
      >
        <ul className="pagination">
          <li
            className={`paginate_button page-item ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
              className="page-link"
            >
              Previous
            </button>
          </li>

          {generatePaginationNumbers().map((page, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                page === currentPage ? "active" : ""
              }`}
            >
              {typeof page === "number" ? (
                <button
                  onClick={() => setCurrentPage(page)}
                  className="page-link"
                >
                  {page}
                </button>
              ) : (
                <span className="page-link">...</span>
              )}
            </li>
          ))}

          <li
            className={`paginate_button page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < totalPages ? prevPage + 1 : prevPage
                )
              }
              className="page-link"
            >
              Next
            </button>
          </li>
        </ul>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <header class="modal-header">
          <h5 class="modal-title">{productName}</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            onClick={handleCloseModal}
          >
            ×
          </button>
        </header>

        <Modal.Body>
          {modalContent && modalContent.length > 0 ? (
            modalContent.map((variant, index) => (
              <div key={index} className="variant-details">
                <p>
                  <span className="bold">Variant Size: </span>
                  {variant.variant_size}
                </p>
                <p>
                  <span className="bold">Price:</span> {variant.price}
                </p>
                <p>
                  <span className="bold">Stock:</span> {variant.variant_stock}
                </p>
                <p>
                  <span className="bold">Colour:</span> {variant.colour}
                </p>
                <p>
                  <span className="bold">Storage:</span> {variant.storage}
                </p>
                <p>
                  <span className="bold">Size:</span> {variant.variant_size}
                </p>
                <p>
                  <span className="bold">Discount Price:</span>
                  {variant.discount_price}
                </p>
                <p>
                  <span className="bold">Variant Status:</span>{" "}
                  {variant.variant_status === "1" ? (
                    <span>Active</span>
                  ) : (
                    <span>In-Active</span>
                  )}
                </p>
                <div className="variant-images">
                  {variant.variant_image &&
                    JSON.parse(variant.variant_image).map((image, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={`https://arasurdigital.com/admin/image/variant/${image}`}
                        alt={`Variant ${imgIndex + 1}`}
                        className="variant-image"
                      />
                    ))}
                </div>
              </div>
            ))
          ) : (
            <p>No variants available...!</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ManageProduct;
